import multicall from '../lib/state/multicall'

import application from './application/reducer'
import connection from './connection/reducer';
import player from './player/reducer';
import transactions from './transactions/reducer';
import user from './user/reducer';
import wallets from './wallets/reducer';

export default {
  application,
  connection,
  multicall: multicall.reducer,
  player,
  transactions,
  user,
  wallets,
};
