import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Pause, Headphones, Play, Heart, Copy } from "react-feather";
import {
  EmailShareButton,
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import { GlobalContext } from "../../providers/UserContextProvider";
import { useCustomWeb3React } from "../../providers/CustomWeb3ReactProvider";
import { ApplicationModal } from "../../state/application/reducer";
import Modal from "../Modal";

import {
  useModalIsOpen,
  useToggleNowPlayingModal,
} from "../../state/application/hooks";
import {
  useIsPlaying,
  useManagePlaylist,
  usePlayerControls,
} from "../../state/player/hooks";

import AudioOption from "../../components/AudioOption";
import LikeSong from "../../components/LikeSong";

import { flexColumnNoWrap, flexRowNoWrap } from "../../theme/styles";

import { ReactComponent as Close } from "../../assets/svg/x.svg";

import Cover from "../../assets/images/cover-02.png";

const AutoColumn = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: ${({ gap, theme }) => (gap && theme.grids[gap]) || gap};
  justify-items: ${({ justify }) => justify && justify};
`;

const AutoRow = styled.div`
  flex-wrap: wrap;
  margin: ${({ gap }) => gap && `-${gap}`};
  justify-content: ${({ justify }) => justify && justify};

  & > * {
    margin: ${({ gap }) => gap} !important;
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: ${({ theme }) => theme.opacity.hover};
  }
`;

const CloseColor = styled(Close)`
  stroke: #fff;
  path {
    stroke: #fff;
  }
`;

const Wrapper = styled.div`
  ${flexColumnNoWrap};
  background-color: #000;
  outline: ${({ theme }) => `1px solid ${theme.backgroundOutline}`};
  box-shadow: ${({ theme }) => theme.deepShadow};
  margin: 0;
  padding: 0;
  width: 100%;
`;

const HeaderRow = styled.div`
  ${flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 600;
  size: 16px;
  color: ${(props) =>
    props.color === "blue" ? ({ theme }) => theme.accentAction : "#ccc"};
  @media (max-width: 768px) {
    padding: 1rem;
  } ;
`;

const ContentWrapper = styled.div`
  background-color: #000;
  padding: 0 1rem 1rem 1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  @media (max-width: 768px) {
    padding: 0 1rem 1rem 1rem;
  } ;
`;

const UpperSection = styled.div`
  position: relative;
  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }
  h5:last-child {
    margin-bottom: 0px;
  }
  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`;

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  } ;
`;

const HoverText = styled.div`
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

export default function NowPlayingModal({
  song,
  handleSongClick,
  handlePostLikeProcessing,
}) {
  const { user } = useContext(GlobalContext);
  const walletModalOpen = useModalIsOpen(ApplicationModal.NOW_PLAYING);
  const toggleNowPlayingModal = useToggleNowPlayingModal();
  const { handleAddSongAndPlay } = useManagePlaylist();
  const isPlaying = useIsPlaying();
  const { handleStop } = usePlayerControls();

  const [showTooltip, setShowTooltip] = useState(false);

  const message = "Check out this BeatFi music - ";

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText("https://app.beatfi.ai/listen/" + song.song_id)
      .then(() => {
        setShowTooltip(true);
        setTimeout(() => {
          setShowTooltip(false);
        }, 1000); // Hide the tooltip after 1 second
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard", err);
      });
  };

  const _handleSongClick = (song) => {
    if (handleSongClick) handleSongClick(song);
  };

  const postLikeProcessing = (song_id) => {
    if (handlePostLikeProcessing) handlePostLikeProcessing(song_id);
  };

  function getModalContent() {
    let headerRow = (
      <HeaderRow>
        <HoverText>
          <>Now Playing</>
        </HoverText>
      </HeaderRow>
    );

    return (
      <UpperSection>
        <CloseIcon onClick={toggleNowPlayingModal}>
          <CloseColor />
        </CloseIcon>{" "}
        {headerRow}
        <ContentWrapper>
          <AutoColumn gap="16px">
            <div className="col-sm-12" key={song.song_id}>
              <div
                className="item r"
                data-id="item-5"
                data-src={song.audio_url}
              >
                <div className="item-media ">
                  <a
                    href="#"
                    className="item-media-content"
                    style={{
                      backgroundImage: `url(${song.cover_url ? song.cover_url : Cover})`,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      _handleSongClick(song);
                    }}
                  ></a>

                  <div className="item-overlay center" style={{opacity: 1}}>
                    {isPlaying ? (
                      <button
                        className="btn-playpause"
                        onClick={() => handleStop()}
                      >
                        <span className="icon-wrapper">
                          <Pause
                            size={24}
                            className="play-icon"
                          />
                        </span>
                      </button>
                    ) : (
                      <button
                        className="btn-playpause"
                        onClick={() => _handleSongClick(song)}
                      >
                        <span className="icon-wrapper">
                          <Play
                            size={24}
                            className="play-icon"
                            style={{
                              paddingLeft: 3,
                              paddingTop: 3,
                            }}
                          />
                        </span>
                      </button>
                    )}
                  </div>
                </div>
                <div className="item-info">
                  <div
                    className={`item-overlay bottom text-right`}
                    style={{ opacity: 1 }}
                  >
                    {user && (
                      <LikeSong
                        liked={song.liked}
                        songId={song.song_id}
                        postUpdate={() => postLikeProcessing(song.song_id)}
                      />
                    )}
                  </div>
                  <div
                    className="item-title text-ellipsis"
                    style={{ color: "#fff", fontSize: 16 }}
                  >
                    {song.title}
                  </div>
                  <div className="item-author text-sm">
                    <span
                      className=""
                      style={{ color: "#ccc", fontSize: 14, paddingTop: 10 }}
                    >
                      {song.creator_name}
                    </span>
                    <div className="pad10"></div>
                    <Headphones size={10} style={{ color: "#ccc" }} />
                    &nbsp;&nbsp;
                    <span className="" style={{ color: "#ccc" }}>
                      {song.play_count}
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Heart size={10} style={{ color: "#ccc" }} />
                    &nbsp;&nbsp;
                    <span className="" style={{ color: "#ccc" }}>
                      {song.like_count ? song.like_count : "0"}
                    </span>
                    <br />
                    <div className="item-author text-sm text-white">
                      <br />
                      Share:
                      <br />
                      <EmailShareButton
                        subject={message + song.title}
                        url={"https://app.beatfi.ai/listen/" + song.song_id}
                      >
                        <EmailIcon size={28} round={true} />
                      </EmailShareButton>
                      &nbsp;&nbsp;
                      <FacebookShareButton
                        hashtag="#BEATFI"
                        url={"https://app.beatfi.ai/listen/" + song.song_id}
                      >
                        <FacebookIcon size={28} round={true} />
                      </FacebookShareButton>
                      &nbsp;&nbsp;
                      <RedditShareButton
                        url={"https://app.beatfi.ai/listen/" + song.song_id}
                        title={message + song.title}
                      >
                        <RedditIcon size={28} round={true} />
                      </RedditShareButton>
                      &nbsp;&nbsp;
                      <TelegramShareButton
                        url={"https://app.beatfi.ai/listen/" + song.song_id}
                        title={message + song.title}
                      >
                        <TelegramIcon size={28} round={true} />
                      </TelegramShareButton>
                      &nbsp;&nbsp;
                      <TwitterShareButton
                        url={"https://app.beatfi.ai/listen/" + song.song_id}
                        title={message + song.title}
                        hashtags={["BEATFI"]}
                        related={["BeatFiAi"]}
                      >
                        <TwitterIcon size={28} round={true} />
                      </TwitterShareButton>
                      &nbsp;&nbsp;
                      <WhatsappShareButton
                        url={"https://app.beatfi.ai/listen/" + song.song_id}
                        title={message + song.title}
                      >
                        <WhatsappIcon size={28} round={true} />
                      </WhatsappShareButton>
                      &nbsp;&nbsp;
                      <button
                        className="react-share__ShareButton"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          padding: 0,
                          font: "inherit",
                          color: "inherit",
                          cursor: "pointer",
                          position: "relative",
                        }}
                        onClick={handleCopyToClipboard}
                      >
                        <Copy size={24} stroke="#fff" />
                        {showTooltip && (
                          <div
                            style={{
                              position: "absolute",
                              bottom: 40,
                              right: 0,
                              width: 100,
                              padding: "5px",
                              backgroundColor: "#333",
                              color: "white",
                              borderRadius: "5px",
                              fontSize: "14px",
                            }}
                          >
                            Share Link is Copied!
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AutoColumn>
        </ContentWrapper>
      </UpperSection>
    );
  }

  return (
    <Modal
      isOpen={walletModalOpen}
      onDismiss={toggleNowPlayingModal}
      minHeight={false}
      maxHeight={90}
      dialogStyles={{ border: "none", borderRadius: 10 }}
      dialogClassName="now-playing-modal"
    >
      <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
  );
}
