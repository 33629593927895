import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Play,
  Pause,
  SkipForward,
  SkipBack,
  Volume2,
  VolumeX,
  List
} from "react-feather";
import IconButton from "./IconButton";
import AudioProgressBar from "./AudioProgressBar";
import VolumeInput from "./VolumeInput";

import Loader from "../Loader";
import LikeSong from "../LikeSong";

import { GlobalContext } from "../../providers/UserContextProvider";

import ImageTrack from "../../assets/images/trackcover.jpg";
import { usePlaylist, usePlayerControls, useCurrentSong, useManagePlaylist, useIsPlaying } from '../../state/player/hooks'; 

function formatDurationDisplay(duration) {
  const min = Math.floor(duration / 60);
  const sec = Math.floor(duration - min * 60);

  const formatted = [min, sec].map((n) => (n < 10 ? "0" + n : n)).join(":");

  return formatted;
}

export default function AudioPlayer() {
  const playlist = usePlaylist();
  const { handleRemoveSong, handleAddSongAndPlay } = useManagePlaylist();
  const { handlePlay, handleStop, handlePlayNext, handlePlayPrevious } = usePlayerControls();
  const currentSong = useCurrentSong();
  const isPlaying = useIsPlaying();

  const { user } = useContext(GlobalContext);
  
  const audioRef = useRef(null);

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [buffered, setBuffered] = useState(0);
  const [volume, setVolume] = useState(1);
  const [playlistVisible, setPlaylistVisible] = useState(false); 

  const durationDisplay = formatDurationDisplay(duration);
  const elapsedDisplay = formatDurationDisplay(currentProgress);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio && currentSong?.audio_url) {
      audio.load();
    }
    if (audio && currentSong?.audio_url && isPlaying) {
      const playPromise = audio.play();
      playPromise.then(() => {
        handlePlay();
      }).catch(error => {
        console.error('Error attempting to play', error);
        handleStop();
      });
    }
  }, [currentSong, isPlaying]);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      handleStop();
    } else {
      handlePlay();
    }
  };

  // Function to remove song
  const onRemoveSong = (song_id) => {
    handleRemoveSong(song_id);
  };

  // Function to play song
  const onPlaySong = (song) => {
    handleAddSongAndPlay(song);
  };

  const handleBufferProgress = (e) => {
    const audio = e.currentTarget;
    const dur = audio.duration;
    if (dur > 0) {
      for (let i = 0; i < audio.buffered.length; i++) {
        if (
          audio.buffered.start(audio.buffered.length - 1 - i) <
          audio.currentTime
        ) {
          const bufferedLength = audio.buffered.end(
            audio.buffered.length - 1 - i,
          );
          setBuffered(bufferedLength);
          break;
        }
      }
    }
  };

  const handleMuteUnmute = () => {
    if (!audioRef.current) return;

    audioRef.current.volume = audioRef.current.volume !== 0 ? 0 : 1;
  };

  const handleVolumeChange = (volumeValue) => {
    if (!audioRef.current) return;
    audioRef.current.volume = volumeValue;
    setVolume(volumeValue);
  };

  const togglePlaylist = () => {
    setPlaylistVisible(!playlistVisible); // Toggle the visibility state
  };

  return (
    <div className="app-player grey bg">
      {currentSong && (
        <audio
          ref={audioRef}
          preload="metadata"
          onDurationChange={(e) => setDuration(e.currentTarget.duration)}
          //onPlaying={() => setIsPlaying(true)}
          onPause={() => handleStop()}
          onEnded={handlePlayNext}
          onCanPlay={(e) => {
            e.currentTarget.volume = volume;
            setIsReady(true);
          }}
          onTimeUpdate={(e) => {
            setCurrentProgress(e.currentTarget.currentTime);
            handleBufferProgress(e);
          }}
          onProgress={handleBufferProgress}
          onVolumeChange={(e) => setVolume(e.currentTarget.volume)}
        >
          <source type="audio/mpeg" src={currentSong.audio_url} />
        </audio>
      )}
      <div
        className="playlist mep-tracks-count-3 has-artwork is-tracklist-closed"
        style={{ width: "100%" }}
      >
        <div
          id="mep_0"
          className="mejs-container mejs-audio"
          tabIndex="0"
          role="application"
          aria-label="Audio Player"
          style={{ width: "100%", height: 40 }}
        >
          <div className="mejs-inner">
            <div className="mejs-layers">
              <div
                className="mejs-poster mejs-layer"
                style={{ display: "none", width: "100%", height: 40 }}
              ></div>

              {user && false && (
              <div className="mejs-track-actions" style={{paddingTop: 10}}>
                <LikeSong
                      liked={true}
                      songId={currentSong.song_id}
                      size={14}
                      //postUpdate={() =>
                      //  postLikeProcessing(currentSong.song_id)
                      //}
                    />
              </div>
              )}
              {currentSong && <div
                className={`mejs-track-artwork ${(isPlaying && false) ? 'is-playing' : 'is-paused'}`}
                style={{ backgroundImage: `url(${currentSong.cover_url ? currentSong.cover_url : ImageTrack})` }}
              ></div>}
              {currentSong && <div className="mejs-track-details">
                <span className="mejs-track-title">
                  <a href="#">{currentSong.title}</a>
                </span>
                <span className="mejs-track-author">
                  <a href="#">{currentSong.creator_name}</a>
                </span>
              </div>
            }
              <div className="mejs-track-source" style={{ display: "none" }}>
                <i></i>
              </div>
              <div
                className="mejs-overlay-error mejs-layer"
                style={{ width: "100%", height: 40, display: "none" }}
              >
                <div className="mejs-error">
                  Oh snap, there was a playback error!
                </div>
              </div>
            </div>
            <div className="mejs-controls">
              <div className="mejs-button mejs-previous-button mejs-previous">
                <button
                  onClick={handlePlayPrevious}
                  disabled={!currentSong}
                  type="button"
                  aria-controls="mep_0"
                  title="Previous Track"
                >
                  <SkipBack size={20} />
                </button>
              </div>
              <div className="mejs-button mejs-playpause-button mejs-play">
                <button
                  type="button"
                  className="beatfi_play_button"
                  aria-controls="mep_0"
                  title="Play"
                  aria-label="Play"
                  disabled={!currentSong?.audio_url}
                  onClick={togglePlayPause}
                >
                  {!isReady && currentSong?.audio_url ? (
                    <Loader size={20} stroke="#fff" />
                  ) : isPlaying ? (
                    <Pause size={20} />
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polygon points="5 3 19 12 5 21 5 3"></polygon></svg>
                  )}
                </button>
              </div>
              <div className="mejs-button mejs-next-button mejs-next">
                <button type="button" aria-controls="mep_0" 
                  onClick={handlePlayNext}
                  disabled={!currentSong}
                  title="Next Track">
                  <SkipForward size={20} />
                </button>
              </div>
              <div className="mejs-time-rail" style={{ display: "none", width: "100%" }}>
              {/*
                              <span
                  className="mejs-time-total mejs-time-slider"
                  aria-label="Time Slider"
                  aria-valuemin="0"
                  aria-valuemax="NaN"
                  aria-valuenow="0"
                  aria-valuetext="00:00"
                  role="slider"
                  tabIndex="0"
                >
                  <span
                    className="mejs-time-buffering"
                    style={{ display: "none" }}
                  ></span>
                  <span className="mejs-time-loaded"></span>
                  <span className="mejs-time-current"></span>
                  <span className="mejs-time-handle"></span>
                  <span className="mejs-time-float" style={{ display: "none" }}>
                    <span className="mejs-time-float-current">00:00</span>
                    <span className="mejs-time-float-corner"></span>
                  </span>
                </span>
                <AudioProgressBar
                    duration={duration}
                    currentProgress={currentProgress}
                    buffered={buffered}
                    onChange={(e) => {
                      if (!audioRef.current) return;

                      audioRef.current.currentTime = e.currentTarget.valueAsNumber;

                      setCurrentProgress(e.currentTarget.valueAsNumber);
                    }}
                  />
                */}
              </div>


              <div className="mejs-time" role="timer" aria-live="off">
                <span className="mejs-currenttime">{elapsedDisplay}</span>
                <span className="mejs-time-separator"> / </span>
                <span className="mejs-duration">{durationDisplay}</span>
              </div>
              <div className="mejs-button mejs-volume-button mejs-mute">
                <button
                  type="button"
                  aria-controls="mep_0"
                  title="Mute"
                  aria-label="Mute"
                  onClick={handleMuteUnmute}
                >
                  {volume === 0 ? (
                    <VolumeX size={20} />
                  ) : (
                    <Volume2 size={20} />
                  )}
                </button>

                <a
                  href="#"
                  className="mejs-volume-slider"
                  style={{ display: "none" }}
                >
                  <span className="mejs-offscreen">
                    Use Up/Down Arrow keys to increase or decrease volume.
                  </span>
                  <div className="mejs-volume-total"></div>
                  <div
                    className="mejs-volume-current"
                    style={{ height: 80, top: 28 }}
                  ></div>
                  <div className="mejs-volume-handle" style={{ top: 25 }}></div>
                </a>
              </div>
              <div
                className="mejs-button mejs-repeat-button mejs-repeat"
                style={{ display: "none" }}
              >
                <button
                  type="button"
                  aria-controls="mep_0"
                  title="Repeat"
                ></button>
              </div>
              <div
                className="mejs-button mejs-shuffle-button mejs-repeat"
                style={{ display: "none" }}
              >
                <button
                  type="button"
                  aria-controls="mep_0"
                  title="Shuffle"
                ></button>
              </div>
              <div className="mejs-button mejs-toggle-playlist-button mejs-toggle-playlist is-closed">
                <button
                  type="button"
                  aria-controls="mep_0"
                  title="Toggle Playlist"
                  onClick={togglePlaylist}
                >
                  <List size={20} />
                </button>
              </div>
            </div>
            <div className="mejs-clear"></div>
          </div>
        </div>
        <ol className={`tracks ${playlistVisible ? '' : 'hide'}`}>
        {playlist && playlist.map((song, index) => (
          <li key={song.song_id} className={`track ${song.song_id == currentSong?.song_id ? 'is-current' : ''}`}>
            <div className="track-action">
              <a href="#" className="track-remove" onClick={() => onRemoveSong(song.song_id)}>×</a>
            </div>
            <div className="track-info" onClick={() => onPlaySong(song)}>
              <span className="track-title">{song.title}</span>
              <span className="track-author">{song.creator_name}</span>
            </div>
          </li>
        ))}
        </ol>

      </div>
    </div>
  );
}
