import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Headphones, Play, Heart, MoreHorizontal } from "react-feather";
import {
  EmailShareButton,
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import { useCustomWeb3React } from "../../providers/CustomWeb3ReactProvider";
import ProtectedPageProvider from "../../providers/ProtectedPageProvider";

import MultiSelect from "../../components/Multiselect";
import { formatBigNumber } from "../../utils/currency";
import { makeAuthenticatedApiCall } from "../../utils/user";
import { SupportedChainId } from "../../constants/chains";
import { useManagePlaylist } from "../../state/player/hooks";

import Cover from "../../assets/images/cover-02.png";

const Content = styled.div`
`;

const Maintenance = () => {
  return (
    <ProtectedPageProvider>
      <Content className="page-content">
        <div className="maintenance_container">
          <div className="box">
            <div className="animation">
              <div className="one spin-one"></div>
              <div className="two spin-two"></div>
              <div className="three spin-one"></div>
            </div>
            <h1>Under maintenance</h1>
            <p>We will be back on 29 Apr 07:00 UTC</p>
          </div>
        </div>
      </Content>
    </ProtectedPageProvider>
  );
};

export default Maintenance;
