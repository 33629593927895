import { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useCustomWeb3React } from "../../providers/CustomWeb3ReactProvider";
import styled from "styled-components";
import axios from "axios";
import {
  Copy,
  Download,
  Plus,
  Play,
  MoreHorizontal,
  Share2,
} from "react-feather";
import {
  EmailShareButton,
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import { GlobalContext } from "../../providers/UserContextProvider";
import { useManagePlaylist } from "../../state/player/hooks";
import { formatBigNumber } from "../../utils/currency";
import { makeAuthenticatedApiCall } from "../../utils/user";
import { SupportedChainId } from "../../constants/chains";
import LikeSong from "../../components/LikeSong";
import AudioOption from "../../components/AudioOption";
import ShareOption from "../../components/ShareOption";

import ArmyImg from "../../assets/images/army.jpg";
import ThankYouImg from "../../assets/images/thankyou.jpg";
import XLogo from "../../assets/icons/x.png";

const ButtonWrapper = styled.div`
  .share-button {
    display: flex;
    padding: 10px 25px !important;
    border-radius: 10px; 
    color: white;
    max-width: 250px;
    align-items: center;
    border: 1px solid #2196f3 !important;
    background-color: #2196f3 !important;
  }
`;

const FullWidthImg = styled.img`
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
`;

const Army = () => {
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://api.beatfi.ai/army/message");
      //console.log(response);
      setMessage(response.data.message);
    } catch (error) {
      setError(error.message);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {}, []);

  return (
    <div className="page-content padding">
      <div className="container">
        <div className="row">
          <h1 className="inline">BeatFi AI Army</h1>
          <div className="col-lg-9">
            Join the BeatFi army and help shape the future of music AI.
            <div className="pad10"></div>
            <FullWidthImg src={ArmyImg} />
            <div className="pad20"></div>
            <h2>Click the button to Share:</h2>
            {isLoading && <div>Loading... Please wait.</div>}
            {message && <ButtonWrapper>
              <TwitterShareButton
                url={"https://beatfi.ai/links"}
                title={message}
                //hashtags={hashtags}
                related={["BeatFiAi"]}
                className="share-button"
              >
                <img width={32} src={XLogo} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Share on X / Twitter
              </TwitterShareButton>
            </ButtonWrapper>}
            <div className="pad90"></div>
            <FullWidthImg src={ThankYouImg} />
          </div>
        </div>
      </div>
      <div className="pad90"></div>
    </div>
  );
};

export default Army;
