import { useEffect, useState, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Headphones, Play, Heart } from "react-feather";

import { useCustomWeb3React } from "../../providers/CustomWeb3ReactProvider";
import ProtectedPageProvider from "../../providers/ProtectedPageProvider";

import ProfileModal from "../../components/ProfileModal";
import ToggleSwitch from "../../components/ToggleSwitch";
import AudioOption from "../../components/AudioOption";
import ShareOption from "../../components/ShareOption";
import LikeSong from "../../components/LikeSong";
import MultiSelect from "../../components/Multiselect";
import TabBar from "../../components/TabBar";
import { formatBigNumber } from "../../utils/currency";
import { makeAuthenticatedApiCall } from "../../utils/user";
import { SupportedChainId } from "../../constants/chains";
import { useManagePlaylist } from "../../state/player/hooks";
import { useToggleProfileModal } from "../../state/application/hooks";

import Cover from "../../assets/images/cover-02.png";

const Content = styled.div``;

const Profile = () => {
  const navigate = useNavigate();
  const { account, chainId } = useCustomWeb3React();
  const { handleAddSongAndPlay, handleAddSong } = useManagePlaylist();
  const toggleProfileModal = useToggleProfileModal();

  const [userProfile, setUserProfile] = useState(null);
  const [likedSongs, setLikedSongs] = useState([]);
  const [createdSongs, setCreatedSongs] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({
    type: "", // 'success' or 'error'
    header: "",
    body: "",
  });

  const fetchUserProfile = async () => {
    try {
      const response1 = await makeAuthenticatedApiCall(
        "/user/profile",
        "get",
      );
      //console.log("API response:", response1);
      setUserProfile(response1.data || []);
    } catch (error) {
      console.error("API error:", error);
      setUserProfile([]);
    }
  };

  const fetchUserSongs = async () => {
    try {
      const response1 = await makeAuthenticatedApiCall(
        "/beats?limit=100",
        "get",
      );
      //console.log("API response:", response1);
      setCreatedSongs(response1.data.data || []);

      for (const record of response1.data.data) {
        if (record.status === "submitted") {
          makeAuthenticatedApiCall(`/beat?id=${record.song_id}`, "get");
          //console.log("API response for submitted song:", response2);
        }
      }
    } catch (error) {
      console.error("API error:", error);
      setCreatedSongs([]);
    }
  };

  const fetchUserLikedSongs = async () => {
    try {
      const response1 = await makeAuthenticatedApiCall(
        "/beats/liked?limit=100",
        "get",
      );
      //console.log("API response:", response1);
      setLikedSongs(response1.data.data || []);
    } catch (error) {
      console.error("API error:", error);
      setLikedSongs([]);
    }
  };

  useEffect(() => {
    fetchUserSongs();
    fetchUserLikedSongs();
    fetchUserProfile();

    const intervalId = setInterval(fetchUserSongs, 10000); // Set interval to call fetchUserSongs every 10000 milliseconds (10 seconds)

    const intervalId2 = setInterval(fetchUserLikedSongs, 10000); // Set interval to call fetchUserSongs every 10000 milliseconds (10 seconds)

    // Cleanup function to clear interval when component unmounts or account changes
    return () => {
      clearInterval(intervalId);
      clearInterval(intervalId2);
    };
  }, [account]);

  const handleSongClick = (song) => {
    //console.log(song)
    if (song.status == "completed") {
      const playlistSong = {
        song_id: song.song_id,
        title: song.title,
        audio_url: song.audio_url,
        creator_name: song.creator_name ? song.creator_name : song.prompt,
      };
      handleAddSongAndPlay(playlistSong);
    }
  };

  const postLikeProcessing = (song_id) => {
    setCreatedSongs((currentSongs) =>
      currentSongs.map((song) =>
        song.song_id === song_id
          ? {
              ...song,
              liked: !song.liked,
              like_count: song.liked
                ? song.like_count - 1
                : song.like_count + 1,
            }
          : song,
      ),
    );
  };

  const handleMakePublic = async (song_id, new_status) => {
    try {
      var data = {
        is_public: new_status,
      };

      const response = await makeAuthenticatedApiCall(
        "/beat/" + song_id + "/public",
        "put",
        data,
      );
      //console.log('Submission resp:', response);
      if (response.status === 200) {
        setCreatedSongs((currentSongs) =>
          currentSongs.map((song) =>
            song.song_id === song_id
              ? {
                  ...song,
                  is_public: new_status,
                }
              : song,
          ),
        );
      }
    } catch (error) {
      console.error("Submission failed:", error);
    }
  };

  return (
    <ProtectedPageProvider>
      <Content className="page-content">
        <div
          id="content"
          className="app-content white bg box-shadow-z2"
          role="main"
        >
          <div className="app-header hidden-lg-up white lt box-shadow-z1">
            <div className="navbar">
              <a href="index.html" className="navbar-brand md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  width="32"
                  height="32"
                >
                  <circle cx="24" cy="24" r="24" fill="rgba(255,255,255,0.2)" />
                  <circle
                    cx="24"
                    cy="24"
                    r="22"
                    fill="#1c202b"
                    className="brand-color"
                  />
                  <circle cx="24" cy="24" r="10" fill="#ffffff" />
                  <circle
                    cx="13"
                    cy="13"
                    r="2"
                    fill="#ffffff"
                    className="brand-animate"
                  />
                  <path d="M 14 24 L 24 24 L 14 44 Z" fill="#FFFFFF" />
                  <circle cx="24" cy="24" r="3" fill="#000000" />
                </svg>

                <img src="images/logo.png" alt="." className="hide" />
                <span className="hidden-folded inline">pulse</span>
              </a>
              <ul className="nav navbar-nav pull-right">
                <li className="nav-item">
                  <a
                    data-toggle="modal"
                    data-target="#aside"
                    className="nav-link"
                  >
                    <i className="material-icons">menu</i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="app-footer app-player grey bg">
            <div className="playlist"></div>
          </div>
          <div className="app-body" id="view">
            <div
              className="page-bg"
              data-stellar-ratio="2"
              style={{ backgroundImage: `url(${Cover})` }}
            ></div>
            <div className="page-content">
              <div className="padding b-b">
                <div className="row-col">
                  <div className="col-sm w w-auto-xs m-b">
                    <div className="item w rounded">
                      <div className="item-media">
                        <div
                          className="item-media-content"
                          style={{ backgroundImage: `url(${Cover})` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="p-l-md no-padding-xs">
                      <span className="page-title">
                        <span className="h1 _800">{userProfile && userProfile.display_name}</span>
                        <a
                          href="#"
                          className="btn btn-sm rounded white text-sm"
                          style={{
                            height: 25,
                            float: "right",
                            backgroundColor: "#666",
                          }}
                          onClick={() => toggleProfileModal()}
                        >
                          Edit Profile
                        </a>
                      </span>
                      <p className="item-desc text-ellipsis text-muted">
                        {userProfile && userProfile.bio}
                      </p>
                      <div className="pad10"></div>

                      <div className="pad10"></div>
                      <div className="block clearfix m-b">
                        <span>0</span>{" "}
                        <span className="text-muted">Albums</span>,&nbsp;&nbsp;
                        <span>{createdSongs && createdSongs.length || "0"}</span>{" "}
                        <span className="text-muted">Tracks</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row-col">
                <div className="col-lg-9 b-r no-border-md">
                  <div className="padding p-y-0 m-b-md">
                    <div className="nav-active-border b-primary bottom m-b-md m-t">
                      <TabBar
                        tabs={["Public Tracks", "Private Tracks", "Likes"]}
                        defaultTab="Public Tracks"
                      >
                        <div className="m-t">
                          <div className="row item-list item-list-by m-b">
                            {createdSongs.filter(item => item.is_public).map((__item, index) => {
                              return (
                                <div className="col-xs-12" key={__item.song_id}>
                                  <div
                                    className="item r"
                                    data-id="item-5"
                                    data-src={__item.audio_url}
                                  >
                                    <div className="item-media ">
                                      <a
                                        href="#"
                                        className="item-media-content"
                                        style={{
                                          backgroundImage: `url(${__item.cover_url ? __item.cover_url : Cover})`,
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleSongClick(__item);
                                        }}
                                      ></a>
                                      {__item.status == "completed" && (
                                        <div className="item-overlay center">
                                          <button
                                            className="btn-playpause"
                                            onClick={() =>
                                              handleSongClick(__item)
                                            }
                                          >
                                            <span className="icon-wrapper">
                                              <Play
                                                size={24}
                                                className="play-icon"
                                                style={{
                                                  paddingLeft: 3,
                                                  paddingTop: 3,
                                                }}
                                              />
                                            </span>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                    <div className="item-info">
                                      <div
                                        className={`item-overlay bottom text-right ${
                                          __item.status == "completed"
                                            ? ""
                                            : "hide"
                                        }`}
                                        style={{ opacity: 1 }}
                                      >
                                        <LikeSong
                                          liked={__item.liked}
                                          songId={__item.song_id}
                                          postUpdate={() =>
                                            postLikeProcessing(__item.song_id)
                                          }
                                        />
                                        <ShareOption song={__item} />
                                        <AudioOption
                                          song={__item}
                                          placement="bottom-end"
                                          isOwned
                                        />
                                      </div>
                                      <div className="item-title text-ellipsis">
                                        <a href="#">{__item.title}</a>
                                      </div>
                                      <div className="item-author text-sm">
                                        <span className="text-muted">
                                          {userProfile && userProfile.display_name}
                                        </span>
                                        <br />
                                        <br />
                                        <Headphones size={10} />
                                        &nbsp;&nbsp;
                                        <span className="text-muted">
                                          {__item.play_count}
                                        </span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Heart size={10} />
                                        &nbsp;&nbsp;
                                        <span className="text-muted">
                                          {__item.like_count
                                            ? __item.like_count
                                            : "0"}
                                        </span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <ToggleSwitch
                                          checked={__item.is_public}
                                          onChange={() =>
                                            handleMakePublic(
                                              __item.song_id,
                                              !__item.is_public,
                                            )
                                          }
                                          label="Public"
                                          mini
                                        />
                                      </div>

                                      {__item.status == "submitted" && (
                                        <div className="p-t">
                                          <span className="label rounded primary">
                                            Processing
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="m-t">
                          <div className="row item-list item-list-by m-b">
                            {createdSongs.filter(item => !item.is_public).map((__item, index) => {
                              return (
                                <div className="col-xs-12" key={__item.song_id}>
                                  <div
                                    className="item r"
                                    data-id="item-5"
                                    data-src={__item.audio_url}
                                  >
                                    <div className="item-media ">
                                      <a
                                        href="#"
                                        className="item-media-content"
                                        style={{
                                          backgroundImage: `url(${__item.cover_url ? __item.cover_url : Cover})`,
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleSongClick(__item);
                                        }}
                                      ></a>
                                      {__item.status == "completed" && (
                                        <div className="item-overlay center">
                                          <button
                                            className="btn-playpause"
                                            onClick={() =>
                                              handleSongClick(__item)
                                            }
                                          >
                                            <span className="icon-wrapper">
                                              <Play
                                                size={24}
                                                className="play-icon"
                                                style={{
                                                  paddingLeft: 3,
                                                  paddingTop: 3,
                                                }}
                                              />
                                            </span>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                    <div className="item-info">
                                      <div
                                        className={`item-overlay bottom text-right ${
                                          __item.status == "completed"
                                            ? ""
                                            : "hide"
                                        }`}
                                        style={{ opacity: 1 }}
                                      >
                                        <LikeSong
                                          liked={__item.liked}
                                          songId={__item.song_id}
                                          postUpdate={() =>
                                            postLikeProcessing(__item.song_id)
                                          }
                                        />
                                        <AudioOption
                                          song={__item}
                                          placement="bottom-end"
                                          isOwned
                                        />
                                      </div>
                                      <div className="item-title text-ellipsis">
                                        <a href="#">{__item.title}</a>
                                      </div>
                                      <div className="item-author text-sm">
                                        <span className="text-muted">
                                          {userProfile && userProfile.display_name}
                                        </span>
                                        { __item.status == "completed" && <>
                                        <br />
                                        <br />
                                        <Headphones size={10} />
                                        &nbsp;&nbsp;
                                        <span className="text-muted">
                                          {__item.play_count}
                                        </span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Heart size={10} />
                                        &nbsp;&nbsp;
                                        <span className="text-muted">
                                          {__item.like_count
                                            ? __item.like_count
                                            : "0"}
                                        </span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <ToggleSwitch
                                          checked={__item.is_public}
                                          onChange={() =>
                                            handleMakePublic(
                                              __item.song_id,
                                              !__item.is_public,
                                            )
                                          }
                                          label="Public"
                                          mini
                                        />
                                        </>}
                                      </div>

                                      {__item.status == "submitted" && (
                                        <div className="p-t">
                                          <span className="label rounded primary">
                                            Processing
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="m-t">
                          <div className="row item-list item-list-by m-b">
                            {likedSongs.map((__item, index) => {
                              return (
                                <div className="col-xs-12" key={__item.song_id}>
                                  <div
                                    className="item r"
                                    data-id="item-5"
                                    data-src={__item.audio_url}
                                  >
                                    <div className="item-media ">
                                      <a
                                        href="#"
                                        className="item-media-content"
                                        style={{
                                          backgroundImage: `url(${__item.cover_url ? __item.cover_url : Cover})`,
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleSongClick(__item);
                                        }}
                                      ></a>
                                      {__item.status == "completed" && (
                                        <div className="item-overlay center">
                                          <button
                                            className="btn-playpause"
                                            onClick={() =>
                                              handleSongClick(__item)
                                            }
                                          >
                                            <span className="icon-wrapper">
                                              <Play
                                                size={24}
                                                className="play-icon"
                                                style={{
                                                  paddingLeft: 3,
                                                  paddingTop: 3,
                                                }}
                                              />
                                            </span>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                    <div className="item-info">
                                      <div
                                        className={`item-overlay bottom text-right ${
                                          __item.status == "completed"
                                            ? ""
                                            : "hide"
                                        }`}
                                        style={{ opacity: 1 }}
                                      >
                                        <LikeSong
                                          liked={true}
                                          songId={__item.song_id}
                                          postUpdate={() =>
                                            fetchUserLikedSongs()
                                          }
                                        />
                                        <ShareOption song={__item} isBeatFiSong />
                                        <AudioOption
                                          song={__item}
                                          placement="bottom-end"
                                        />
                                      </div>
                                      <div className="item-title text-ellipsis">
                                        <a href="#">{__item.title}</a>
                                      </div>
                                      <div className="item-author text-sm">
                                        <span className="text-muted">
                                          {__item.creator_name}
                                        </span>
                                        <br />
                                        <br />
                                        <Headphones size={10} />
                                        &nbsp;&nbsp;
                                        <span className="text-muted">
                                          {__item.play_count}
                                        </span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Heart size={10} />
                                        &nbsp;&nbsp;
                                        <span className="text-muted">
                                          {__item.like_count
                                            ? __item.like_count
                                            : "0"}
                                        </span>
                                      </div>

                                      {__item.status == "submitted" && (
                                        <div className="p-t">
                                          <span className="label rounded primary">
                                            Processing
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                      </TabBar>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 w-xxl w-auto-md">
                  <div className="padding" data-ui-jp="stick_in_parent"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>
      <ProfileModal onProfileUpdated={()=> fetchUserProfile()} />
    </ProtectedPageProvider>
  );
};

export default Profile;
