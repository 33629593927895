import { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import { Headphones, Play, Heart, MoreHorizontal } from "react-feather";
import axios from "axios";

import { useCustomWeb3React } from "../../providers/CustomWeb3ReactProvider";
import { GlobalContext } from "../../providers/UserContextProvider";
import { useManagePlaylist } from "../../state/player/hooks";
import { formatBigNumber } from "../../utils/currency";
import { makeAuthenticatedApiCall } from "../../utils/user";
import { SupportedChainId } from "../../constants/chains";
import LikeSong from "../../components/LikeSong";
import AudioOption from "../../components/AudioOption";
import ShareOption from "../../components/ShareOption";

import Cover from "../../assets/images/cover-02.png";

const Dashboard = () => {
  const navigate = useNavigate();
  const { account, chainId } = useCustomWeb3React();
  const { handleAddSongAndPlay } = useManagePlaylist();
  const { user } = useContext(GlobalContext);

  const [publicDiscovery, setPublicDiscovery] = useState(null);
  const [authDiscovery, setAuthDiscovery] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDiscoveryData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://api.beatfi.ai/discovery/new");
      setPublicDiscovery(response.data);
      //console.log(response.data);
    } catch (error) {
      setError(error.message);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDiscoveryData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response1 = await makeAuthenticatedApiCall(
          "/discovery/auth",
          "get",
        );
        //console.log("API response:", response1);
        setAuthDiscovery(response1.data || null);
      } catch (error) {
        setError(error.message);
        //console.error("Error fetching data:", error);
        setAuthDiscovery(null);
        fetchDiscoveryData();
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [account, user]);

  const postLikeProcessing = (song_id) => {
    setPublicDiscovery((currentCharts) => ({
      ...currentCharts,
      top_featured: {
        ...currentCharts.top_featured,
        liked:
          currentCharts.top_featured.song_id === song_id
            ? !currentCharts.top_featured.liked
            : currentCharts.top_featured.liked,
        like_count:
          currentCharts.top_featured.song_id === song_id
            ? currentCharts.top_featured.liked
              ? currentCharts.top_featured.like_count - 1
              : currentCharts.top_featured.like_count + 1
            : currentCharts.top_featured.like_count,
      },
      featured: currentCharts.featured.map((song) =>
        song.song_id === song_id
          ? {
              ...song,
              liked: !song.liked,
              like_count: song.liked
                ? song.like_count - 1
                : song.like_count + 1,
            }
          : song,
      ),
      new: currentCharts.new.map((song) =>
        song.song_id === song_id
          ? {
              ...song,
              liked: !song.liked,
              like_count: song.liked
                ? song.like_count - 1
                : song.like_count + 1,
            }
          : song,
      ),
      random: currentCharts.random.map((song) =>
        song.song_id === song_id
          ? {
              ...song,
              liked: !song.liked,
              like_count: song.liked
                ? song.like_count - 1
                : song.like_count + 1,
            }
          : song,
      ),
    }));

    if (user) {
      setAuthDiscovery((currentCharts) => ({
        ...currentCharts,
        top_featured: {
          ...currentCharts.top_featured,
          liked:
            currentCharts.top_featured.song_id === song_id
              ? !currentCharts.top_featured.liked
              : currentCharts.top_featured.liked,
          like_count:
            currentCharts.top_featured.song_id === song_id
              ? currentCharts.top_featured.liked
                ? currentCharts.top_featured.like_count - 1
                : currentCharts.top_featured.like_count + 1
              : currentCharts.top_featured.like_count,
        },
        featured: currentCharts.featured.map((song) =>
          song.song_id === song_id
            ? {
                ...song,
                liked: !song.liked,
                like_count: song.liked
                  ? song.like_count - 1
                  : song.like_count + 1,
              }
            : song,
        ),
        new: currentCharts.new.map((song) =>
          song.song_id === song_id
            ? {
                ...song,
                liked: !song.liked,
                like_count: song.liked
                  ? song.like_count - 1
                  : song.like_count + 1,
              }
            : song,
        ),
        random: currentCharts.random.map((song) =>
          song.song_id === song_id
            ? {
                ...song,
                liked: !song.liked,
                like_count: song.liked
                  ? song.like_count - 1
                  : song.like_count + 1,
              }
            : song,
        ),
      }));
    }
  };

  const handleSongClick = (song) => {
    if (song?.song_id && song?.title && song?.audio_url) {
      const playlistSong = {
        song_id: song.song_id,
        title: song.title,
        audio_url: song.audio_url,
        creator_name:
          song.owner && song.owner.creator_name
            ? song.owner.creator_name
            : song.creator_name,
      };
      handleAddSongAndPlay(playlistSong);
    }
  };

  const discovery = authDiscovery ? authDiscovery : publicDiscovery;

  return (
    <div className="page-content padding">
      <div className="banner-container">
        <div className="cb-static-text h3">
          The Chart@BeatFi Contest (Season 1)
          <div className="cb-cue h4">$BEATFI and $USDT to be won!</div>
        </div>
        <div className="sound-animation">
          <div className="sound-icon">
            <div className="sound-wave">
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
            </div>
          </div>
        </div>
        <div className="pad20"></div>
        <Link
          to="https://medium.com/@BeatFiAI/unleashing-creativity-with-blockchain-the-chart-beatfi-launch-contest-season-1-7a8245aad35e"
          target="_blank"
          className="btn btn-outline rounded b-primary text-white"
          style={{border: "1px solid #1ed891"}}
        >
          How to participate?
        </Link>
        <div className="pad10"></div>
      </div>

      <div className="page-title">
        <h1 className="inline m-a-0">Discover</h1>
      </div>
      <div className="container">
        <div className="row item-masonry item-info-overlay">
          <div className="col-sm-6 text-white">
            <div
              className="item r"
              data-id="item-115"
              data-src={discovery?.top_featured?.audio_url}
            >
              <div className="item-media primary">
                <a
                  href="#"
                  className="item-media-content"
                  style={{ backgroundImage: `url(${discovery?.top_featured.cover_url ? discovery?.top_featured.cover_url : Cover})` }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSongClick(discovery?.top_featured);
                  }}
                ></a>
                <div className="item-overlay center">
                  <button
                    className="btn-playpause"
                    onClick={() => handleSongClick(discovery?.top_featured)}
                  >
                    <span className="icon-wrapper">
                      <Play
                        size={24}
                        className="play-icon"
                        style={{ paddingLeft: 3, paddingTop: 3 }}
                      />
                    </span>
                  </button>
                </div>
              </div>
              <div className="item-info">
                <div
                  className="item-overlay bottom text-right"
                  style={{ opacity: 1 }}
                >
                  {user && (
                    <LikeSong
                      liked={discovery?.top_featured?.liked}
                      songId={discovery?.top_featured?.song_id}
                      postUpdate={() =>
                        postLikeProcessing(discovery?.top_featured?.song_id)
                      }
                    />
                  )}
                  <ShareOption song={discovery?.top_featured} isBeatFiSong />
                  <AudioOption song={discovery?.top_featured} />
                </div>
                <div className="item-title">
                  <a href="#">{discovery?.top_featured?.title}</a>
                </div>
                <div className="item-author text-sm ">
                  <a href="#" className="text-muted">
                    {discovery?.top_featured?.owner?.creator_name}
                  </a>
                  <br />
                  <Headphones size={10} />
                  &nbsp;&nbsp;
                  <span className="text-muted">
                    {discovery?.top_featured?.play_count}
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Heart size={10} />
                  &nbsp;&nbsp;
                  <span className="text-muted">
                    {discovery?.top_featured?.like_count
                      ? discovery?.top_featured?.like_count
                      : "0"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 text-white">
            <div className="row">
              {discovery?.featured?.map((song) => (
                <div className="col-sm-6 col-6" key={song.song_id}>
                  <div
                    className="item r"
                    data-id={song.song_id}
                    data-src={song.audio_url}
                  >
                    <div className="item-media ">
                      <a
                        href="#"
                        className="item-media-content"
                        style={{ backgroundImage: `url(${song.cover_url ? song.cover_url : Cover})` }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleSongClick(song);
                        }}
                      ></a>
                      <div className="item-overlay center">
                        <button
                          className="btn-playpause"
                          onClick={() => handleSongClick(song)}
                        >
                          <span className="icon-wrapper">
                            <Play
                              size={24}
                              className="play-icon"
                              style={{ paddingLeft: 3, paddingTop: 3 }}
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="item-info">
                      <div
                        className="item-overlay bottom text-right"
                        style={{ opacity: 1 }}
                      >
                        {user && (
                          <LikeSong
                            liked={song.liked}
                            songId={song.song_id}
                            postUpdate={() => postLikeProcessing(song.song_id)}
                          />
                        )}
                        <ShareOption song={song} isBeatFiSong />
                        <AudioOption song={song} />
                      </div>
                      <div className="item-title">
                        <a href="#">{song.title}</a>
                      </div>
                      <div className="item-author text-sm">
                        <a href="#" className="text-muted">
                          {song.owner?.creator_name}
                        </a>
                        <br />
                        <Headphones size={10} />
                        &nbsp;&nbsp;
                        <span className="text-muted">{song.play_count}</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Heart size={10} />
                        &nbsp;&nbsp;
                        <span className="text-muted">
                          {song.like_count ? song.like_count : "0"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="b-b m-y"></div>
      <div className="container">
        <h2 className="widget-title h2 m-b">New</h2>
        <div className="row">
          {discovery?.new?.map((song) => (
            <div className="col-6 col-sm-4 col-md-3" key={song.song_id}>
              <div
                className="item r"
                data-id={song.song_id}
                data-src={song.audio_url}
              >
                <div className="item-media ">
                  <a
                    href=""
                    className="item-media-content"
                    style={{ backgroundImage: `url(${song.cover_url ? song.cover_url : Cover})` }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSongClick(song);
                    }}
                  ></a>
                  <div className="item-overlay center">
                    <button
                      className="btn-playpause"
                      onClick={() => handleSongClick(song)}
                    >
                      <span className="icon-wrapper">
                        <Play
                          size={24}
                          className="play-icon"
                          style={{ paddingLeft: 3, paddingTop: 3 }}
                        />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="item-info">
                  <div
                    className="item-overlay bottom text-right"
                    style={{ opacity: 1 }}
                  >
                    {user && (
                      <LikeSong
                        liked={song.liked}
                        songId={song.song_id}
                        postUpdate={() => postLikeProcessing(song.song_id)}
                      />
                    )}
                    <ShareOption song={song} isBeatFiSong />
                    <AudioOption song={song} />
                  </div>
                  <div className="item-title ">
                    <a href="#">{song.title}</a>
                  </div>
                  <div className="item-author text-sm  ">
                    <a href="#" className="text-muted">
                      {song.owner?.creator_name}
                    </a>
                    <br />
                    <Headphones size={10} />
                    &nbsp;&nbsp;
                    <span className="text-muted">{song.play_count}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Heart size={10} />
                    &nbsp;&nbsp;
                    <span className="text-muted">
                      {song.like_count ? song.like_count : "0"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="b-b m-y"></div>
      <div className="container">
        <h2 className="widget-title h2 m-b">For You</h2>
        <div className="row item-list item-list-md m-b">
          {discovery?.random?.map((__item) => (
            <div className="col-sm-6" key={__item.song_id}>
              <div
                className="item r"
                data-id="item-5"
                data-src={__item.audio_url}
              >
                <div className="item-media ">
                  <a
                    href="#"
                    className="item-media-content"
                    style={{
                      backgroundImage: `url(${__item.cover_url ? __item.cover_url : Cover})`,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSongClick(__item);
                    }}
                  ></a>

                  <div className="item-overlay center">
                    <button
                      className="btn-playpause"
                      onClick={() => handleSongClick(__item)}
                    >
                      <span className="icon-wrapper">
                        <Play
                          size={24}
                          className="play-icon"
                          style={{
                            paddingLeft: 3,
                            paddingTop: 3,
                          }}
                        />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="item-info">
                  <div
                    className={`item-overlay bottom text-right`}
                    style={{ opacity: 1 }}
                  >
                    {user && (
                      <LikeSong
                        liked={__item.liked}
                        songId={__item.song_id}
                        postUpdate={() => postLikeProcessing(__item.song_id)}
                      />
                    )}
                    <ShareOption song={__item} isBeatFiSong />
                    <AudioOption song={__item} placement="bottom-end" />
                  </div>
                  <div className="item-title ">
                    <a href="#">{__item.title}</a>
                  </div>
                  <div className="item-author text-sm">
                    <span className="text-muted">
                      {__item.owner?.creator_name}
                    </span>
                    <br />
                    <br />
                    <Headphones size={10} />
                    &nbsp;&nbsp;
                    <span className="text-muted">{__item.play_count}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Heart size={10} />
                    &nbsp;&nbsp;
                    <span className="text-muted">
                      {__item.like_count ? __item.like_count : "0"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pad90"></div>
      <div className="pad90"></div>
    </div>
  );
};

export default Dashboard;
