import { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useCustomWeb3React } from "../../providers/CustomWeb3ReactProvider";
import styled from "styled-components";
import { Headphones, Play, Heart, MoreHorizontal } from "react-feather";
import axios from "axios";

import { GlobalContext } from "../../providers/UserContextProvider";
import { useManagePlaylist } from "../../state/player/hooks";
import { formatBigNumber } from "../../utils/currency";
import { makeAuthenticatedApiCall } from "../../utils/user";
import { SupportedChainId } from "../../constants/chains";
import LikeSong from "../../components/LikeSong";
import AudioOption from "../../components/AudioOption";
import ShareOption from "../../components/ShareOption";

import Cover from "../../assets/images/cover-02.png";

const Chart = () => {
  const navigate = useNavigate();
  const { account, chainId } = useCustomWeb3React();
  const { handleAddSongAndPlay } = useManagePlaylist();
  const { user } = useContext(GlobalContext);

  const [charts, setCharts] = useState([]);
  const [authCharts, setAuthCharts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchChartData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("https://api.beatfi.ai/chart/top");
      setCharts(response.data);
    } catch (error) {
      setError(error.message);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData();
    
    const intervalId = setInterval(fetchChartData, 10000); // Set interval to call fetchData every 10000 milliseconds (10 seconds)

    // Cleanup function to clear interval when component unmounts or account changes
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response1 = await makeAuthenticatedApiCall(
          "/chart/auth/top",
          "get",
        );
        //console.log("API response:", response1);
        setAuthCharts(response1.data || []);
      } catch (error) {
        setError(error.message);
        //console.error("Error fetching data:", error);
        setAuthCharts([]);
        fetchChartData();
      } finally {
        setIsLoading(false);
      }
    };

    fetchData(); 

    const intervalId = setInterval(fetchData, 10000); // Set interval to call fetchData every 10000 milliseconds (10 seconds)

    // Cleanup function to clear interval when component unmounts or account changes
    return () => {
      clearInterval(intervalId);
    };
  }, [account, user]);

  const postLikeProcessing = (song_id) => {
    setCharts((currentSongs) =>
      currentSongs.map((song) =>
        song.song_id === song_id
          ? {
              ...song,
              liked: !song.liked,
              like_count: song.liked
                ? song.like_count - 1
                : song.like_count + 1,
            }
          : song,
      ),
    );

    if (user) {
      setAuthCharts((currentSongs) =>
        currentSongs.map((song) =>
          song.song_id === song_id
            ? {
                ...song,
                liked: !song.liked,
                like_count: song.liked
                  ? song.like_count - 1
                  : song.like_count + 1,
              }
            : song,
        ),
      );
    }
  };

  const handleSongClick = (song) => {
    if (song?.song_id && song?.title && song?.audio_url) {
      const playlistSong = {
        song_id: song.song_id,
        title: song.title,
        audio_url: song.audio_url,
        creator_name: song.creator_name,
      };
      handleAddSongAndPlay(playlistSong);
    }
  };

  const finalCharts = authCharts && authCharts.length > 0 ? authCharts : charts;

  return (
    <div className="page-content padding">
      <div className="banner-container">
        <div className="cb-static-text h3">
          The Chart@BeatFi Contest (Season 1)
          <div className="cb-cue h4">$BEATFI and $USDT to be won!</div>
        </div>
        <div className="sound-animation">
          <div className="sound-icon">
            <div className="sound-wave">
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
              <i className="bar"></i>
            </div>
          </div>
        </div>
        <div className="pad20"></div>
        <Link
          to="https://medium.com/@BeatFiAI/unleashing-creativity-with-blockchain-the-chart-beatfi-launch-contest-season-1-7a8245aad35e"
          target="_blank"
          className="btn btn-outline rounded b-primary text-white"
          style={{border: "1px solid #1ed891"}}
        >
          How to participate?
        </Link>
        <div className="pad10"></div>
      </div>
      <div className="page-title ">
        <h1 className="inline m-a-0">Charts</h1>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-9 item-list item-list-md item-list-li m-b">
            {finalCharts?.map((__item, index) => {
              return (
                <div className="col-xs-12" key={__item.song_id}>
                  <div
                    className="item r"
                    data-id="item-5"
                    data-src={__item.audio_url}
                  >
                    <div className="item-media ">
                      <a
                        href="#"
                        className="item-media-content"
                        style={{
                          backgroundImage: `url(${__item.cover_url ? __item.cover_url : Cover})`,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleSongClick(__item);
                        }}
                      ></a>

                      <div className="item-overlay center">
                        <button
                          className="btn-playpause"
                          onClick={() => handleSongClick(__item)}
                        >
                          <span className="icon-wrapper">
                            <Play
                              size={24}
                              className="play-icon"
                              style={{
                                paddingLeft: 3,
                                paddingTop: 3,
                              }}
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="item-info">
                      <div
                        className={`item-overlay bottom text-right`}
                        style={{ opacity: 1 }}
                      >
                        {user && (
                          <LikeSong
                            liked={__item.liked}
                            songId={__item.song_id}
                            postUpdate={() =>
                              postLikeProcessing(__item.song_id)
                            }
                          />
                        )}

                        <ShareOption song={__item} isBeatFiSong />
                        <AudioOption
                          song={__item}
                          placement="bottom-end"
                        />
                      </div>
                      <div className="item-title">
                        <a href="#">{__item.title}</a>
                      </div>
                      <div className="item-author text-sm">
                        <span className="text-muted">
                          {__item.creator_name}
                        </span>
                        <br />
                        <br />
                        <Headphones size={10} />
                        &nbsp;&nbsp;
                        <span className="text-muted">{__item.play_count}</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Heart size={10} />
                        &nbsp;&nbsp;
                        <span className="text-muted">
                          {__item.like_count ? __item.like_count : "0"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="pad90"></div>
      <div className="pad90"></div>
    </div>
  );
};

export default Chart;
