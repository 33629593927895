import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import NowPlayingModal from "../../components/NowPlayingModal";

import Dashboard from "../Dashboard";

import { useCustomWeb3React } from "../../providers/CustomWeb3ReactProvider";
import { GlobalContext } from "../../providers/UserContextProvider";
import { useManagePlaylist } from "../../state/player/hooks";
import { useOpenModal } from "../../state/application/hooks";
import { ApplicationModal } from "../../state/application/reducer";
import { makeAuthenticatedApiCall } from "../../utils/user";

import Cover from "../../assets/images/cover-02.png";

const Listen = () => {
  const { songId } = useParams();
  const { account, chainId } = useCustomWeb3React();
  const { user } = useContext(GlobalContext);

  const openNowPlayingModal = useOpenModal(ApplicationModal.NOW_PLAYING);
  const { handleAddSongAndPlay } = useManagePlaylist();

  const [isLoading, setIsLoading] = useState(false);
  const [nowPlayingSong, setNowPlayingSong] = useState(null);
  const [authNowPlayingSong, setAuthNowPlayingSong] = useState(null);
  const [error, setError] = useState(null);

  const fetchSongData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://api.beatfi.ai/beat/public/?id=${songId}`,
      );
      if (!response.ok) throw new Error("Song not found");

      const data = await response.json();

      setNowPlayingSong(data);

      handleAddSongAndPlay(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Optionally navigate to another page if there's an error
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSongData();
  }, [songId]);

  const fetchAuthData = async () => {
    setIsLoading(true);
    try {
      const response1 = await makeAuthenticatedApiCall(
        `/beat/public/auth/?id=${songId}`,
        "get",
      );
      //console.log("API response:", response1);
      setAuthNowPlayingSong(response1.data || null);
    } catch (error) {
      setError(error.message);
      //console.error("Error fetching data:", error);
      setAuthNowPlayingSong(null);
      fetchSongData();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAuthData();
  }, [songId, account, user]);

  useEffect(() => {
    if (nowPlayingSong) {
      setTimeout(() => {
        openNowPlayingModal();
      }, 400);
    }
  }, [nowPlayingSong]);

  const handleSongClick = (song) => {
    handleAddSongAndPlay(song);
  };

  const postLikeProcessing = (song_id) => {
    if (user) {
      setAuthNowPlayingSong({
        ...authNowPlayingSong,
        liked: !authNowPlayingSong.liked,
        like_count: authNowPlayingSong.liked
          ? authNowPlayingSong.like_count - 1
          : authNowPlayingSong.like_count + 1,
      });

      fetchAuthData();
    }
  };

  const finalSong = authNowPlayingSong ? authNowPlayingSong : nowPlayingSong;

  //console.log(finalSong)
  return (
    <>
      {finalSong && (
        <NowPlayingModal
          song={finalSong}
          handleSongClick={(_song) => handleSongClick(_song)}
          handlePostLikeProcessing={(song_id) => postLikeProcessing(song_id)}
        />
      )}
      <Dashboard />
    </>
  );
};

export default Listen;
