import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Headphones, Play, Heart } from "react-feather";

import { useCustomWeb3React } from "../../providers/CustomWeb3ReactProvider";
import ProtectedPageProvider from "../../providers/ProtectedPageProvider";

import ToggleSwitch from "../../components/ToggleSwitch";
import AudioOption from "../../components/AudioOption";
import ShareOption from "../../components/ShareOption";
import LikeSong from "../../components/LikeSong";
import MultiSelect from "../../components/Multiselect";
import { formatBigNumber } from "../../utils/currency";
import { makeAuthenticatedApiCall } from "../../utils/user";
import { SupportedChainId } from "../../constants/chains";
import { useManagePlaylist } from "../../state/player/hooks";

import Cover from "../../assets/images/cover-02.png";

const Content = styled.div`
  .item-info .text-sm {
    overflow: auto;
  }

  .row-height {
    height: 100vh;
  }

  /*Set up the columns with a 100% height, body color and overflow scroll*/

  .left {
    overflow-y: auto;
    background-color: #17171b;
  }

  .mid {
    overflow-y: auto;
    background-color: #17171b;
  }

  .right {
    overflow-y: auto;
    background-color: #17171b;
  }

  @media only screen and (max-width: 767px) {
    .left::-webkit-scrollbar,
    .mid::-webkit-scrollbar,
    .right::-webkit-scrollbar {
      display: none;
    }

    .left,
    mid,
    right {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  @media only screen and (min-width: 768px) {
    .row-height {
      height: calc(100vh - 56px - 56px);
      min-height: calc(100vh - 56px - 56px);
    }

    .left,
    .mid,
    .right {
      height: 100%;
    }

    .left,
    .mid {
      border-right: 1px solid rgba(120, 130, 140, 0.2);
    }
  }

  .img {
    width: 100%;
    flex-shrink: 0;
    display: block;
    object-fit: cover;
  }
  .list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .text_trsf-cap {
    text-transform: capitalize;
  }
  .flex-row {
    display: flex;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  ._align_center {
    align-items: center;
  }
  ._align_start {
    align-items: flex-start;
  }
  ._align_end {
    align-items: flex-end;
  }
  ._justify_center {
    justify-content: center;
  }
  ._justify_start {
    justify-content: flex-start;
  }
  ._justify_end {
    justify-content: flex-end;
  }
  ._justify_space-btwn {
    justify-content: space-between;
  }
  .text_overflow {
    width: 66%;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
  }
  .music-player__info {
    width: 56.3%;
    cursor: pointer;
    line-height: 1.8;
    overflow: hidden;
    font-weight: bold;
    padding: 0 0.0625em;
    white-space: nowrap;
  }
  .music-player__info > * {
    margin: 0 auto;
    pointer-events: none;
  }
  .music-player__singer-name {
    font-size: 14px;
    width: max-content;
  }
  .music-player__subtitle {
    font-size: 14px;
    color: #aaa;
  }
  .music-player__singer-name.animate,
  .music-player__subtitle.animate {
    display: flex;
    gap: 0 1.5625em;
    animation: subtitle 12s 1.2s linear infinite;
  }
  @keyframes subtitle {
    80%,
    100% {
      transform: translate3d(calc((100% + 1.5625em) / -2), 0, 0);
    }
  }
  .music-player__playlist {
    height: 100%;
    overflow: hidden auto;
  }
  .music-player__song {
    cursor: pointer;
    margin-bottom: 0.75em;
    padding-bottom: 0.75em;
    border-bottom: 1.938px solid #d8d8d859;
  }
  .music-player__song audio {
    display: none;
  }
  .music-player__song-img {
    width: 3.8125em;
    height: 3.8125em;
    border-radius: 1.625em;
  }
  .music-player__playlist-info {
    width: 100%;
    overflow: hidden;
    line-height: 1.3;
    font-size: 15px;
    margin-left: 0.7875em;
  }
  .music-player__song-duration {
    font-weight: bold;
    font-size: 0.7875em;
    color: #0000006b;
  }
`;

const instrumentOptions = [
  { label: "Piano", value: "Piano" },
  { label: "Acoustic Guitar", value: "Acoustic Guitar" },
  { label: "Electric Guitar", value: "Electric Guitar" },
  { label: "Violin", value: "Violin" },
  { label: "Drums", value: "Drums" },
  { label: "Bass", value: "Bass" },
  { label: "Saxophone", value: "Saxophone" },
  { label: "Synthesizer", value: "Synthesizer" },
  { label: "Flute", value: "Flute" },
  { label: "Human Voice", value: "Human Voice" },
];

function Lyrics({ lyrics }) {
  //const formattedLyrics = lyrics.replace(/\n\[/g, '\n\n[');
  const formattedLyrics = lyrics.replace(/\[[^\]]*\]/g, '');

  var lines = formattedLyrics.split('\n');

  return (
      <div className="">
          {lines.map((line, index) => (
              <React.Fragment key={index}>
                  {line}<br />
              </React.Fragment>
          ))}
      </div>
  );
}

const Create = () => {
  const navigate = useNavigate();
  const { account, chainId } = useCustomWeb3React();
  const { handleAddSongAndPlay, handleAddSong } = useManagePlaylist();

  const [songDescription, setSongDescription] = useState("");
  const [songName, setSongName] = useState("");
  const [website, setWebsite] = useState("");
  const [emotion, setEmotion] = useState("");
  const [genre, setGenre] = useState("");
  const [key, setKey] = useState("");
  const [tempo, setTempo] = useState("Moderate");
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [createdSongs, setCreatedSongs] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showSongDetails, setShowSongDetails] = useState(false);
  const [messageContent, setMessageContent] = useState({
    type: "", // 'success' or 'error'
    header: "",
    body: "",
  });

  const fetchData = async () => {
    try {
      const response1 = await makeAuthenticatedApiCall(
        "/beats?limit=100",
        "get",
      );
      //console.log("API response:", response1);
      setCreatedSongs(response1.data.data || []);

      for (const record of response1.data.data) {
        if (record.status === "submitted") {
          makeAuthenticatedApiCall(`/beat?id=${record.song_id}`, "get");
          //console.log("API response for submitted song:", response2);
        }
      }
    } catch (error) {
      console.error("API error:", error);
      setCreatedSongs([]);
    }
  };

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(fetchData, 10000); // Set interval to call fetchData every 10000 milliseconds (10 seconds)

    // Cleanup function to clear interval when component unmounts or account changes
    return () => {
      clearInterval(intervalId);
    };
  }, [account]);

  const handleSelectionChange = (event) => {
    const value = event.target.value;
    const newSelection = [...selectedOptions];
    const index = newSelection.indexOf(value);

    if (index > -1) {
      // Option is already selected, remove it
      newSelection.splice(index, 1);
    } else {
      // Add new option if it doesn't exceed the limit
      if (newSelection.length < 3) {
        newSelection.push(value);
      } else {
        // Optionally, alert the user that the limit has been reached
        alert("You can select up to 3 options only.");
      }
    }

    setSelectedOptions(newSelection);
  };

  const handleSongClick = (song) => {
    if (song.status == "completed") {
      const playlistSong = {
        song_id: song.song_id,
        title: song.title,
        audio_url: song.audio_url,
        creator_name: song.prompt,
        cover_url: song.cover_url,
      };
      handleAddSongAndPlay(playlistSong);
    }
  };

  const postLikeProcessing = (song_id) => {
    setCreatedSongs((currentSongs) =>
      currentSongs.map((song) =>
        song.song_id === song_id
          ? {
              ...song,
              liked: !song.liked,
              like_count: song.liked
                ? song.like_count - 1
                : song.like_count + 1,
            }
          : song,
      ),
    );
  };

  const handleMakePublic = async (song_id, new_status) => {
    try {
      var data = {
        is_public: new_status,
      };

      const response = await makeAuthenticatedApiCall(
        "/beat/" + song_id + "/public",
        "put",
        data,
      );
      //console.log('Submission resp:', response);
      if (response.status === 200) {
        setCreatedSongs((currentSongs) =>
          currentSongs.map((song) =>
            song.song_id === song_id
              ? {
                  ...song,
                  is_public: new_status,
                }
              : song,
          ),
        );
      }
    } catch (error) {
      console.error("Submission failed:", error);
    }
  };

  const showDetails = (song) => {
    //console.log("show show", song)

    if (song.status == "completed") {
      setShowSongDetails(song)
    }
  };

  // success resp: {id: "036efa53-2505-4ac1-b235-a0765185987d", status: "submitted"}
  // failed resp: {error: "1001", message: "Daily generation limit reached"}
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Simple required field validation
    if (!songDescription.trim()) {
      alert("Please describe the song."); // Basic alert, consider using a more user-friendly notification method
      return;
    }

    // Prepare the data payload
    var data = {
      description: songDescription,
      song_name: songName, // Optional, send only if provided
    };

    if (isAdvancedMode) {
      data.tempo = tempo;
      data.emotion = emotion;
      data.genre = genre;
      data.key = key;
    }

    setIsSubmitting(true); // Set loading state

    try {
      const response = await makeAuthenticatedApiCall("/beat", "post", data);
      //console.log('Submission success:', response.data);
      //console.log('Submission resp:', response);
      if (response.status === 200) {
        setMessageContent({
          type: "success",
          header: "Song submission successful!",
          body: "Please wait while your song is being generated.",
        });

        // Clear the form if submission is successful
        setSongDescription("");
        setSongName("");
        setIsAdvancedMode(false);

        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false); // Hide after 5 seconds
        }, 5000);
      } else {
        setShowMessage(true);
        setMessageContent({
          type: "error",
          header: "Submission failed",
          body: response?.error?.message || "Please try again later.",
        });
        setTimeout(() => {
          setShowMessage(false); // Hide after 5 seconds
        }, 10000);
      }
    } catch (error) {
      //console.error('Submission failed:', error);

      setMessageContent({
        type: "error",
        header: "Submission failed",
        body: "Please try again later.",
      });

      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false); // Hide after 5 seconds
      }, 10000);
    }

    setIsSubmitting(false); // Reset loading state
  };

  return (
    <ProtectedPageProvider>
      <Content className="page-content">
        <div className="container-fluid">
          <div className="row row-height">
            <div className="col-sm-12 col-md-6 col-lg-4 left">
              <form className="p-t p-b" onSubmit={handleSubmit}>
                {showMessage && (
                  <div
                    className={`m-b ${
                      messageContent.type === "success" ? "primary" : "danger"
                    }`}
                  >
                    <div className="box-header">
                      <h3>{messageContent.header}</h3>
                      <small>{messageContent.body}</small>
                    </div>
                  </div>
                )}

                <div className="form-group">
                  <label
                    htmlFor="songDescription"
                    className="form-control-label"
                    style={{ display: "block" }}
                  >
                    Describe the song
                  </label>
                  <textarea
                    id="songDescription"
                    value={songDescription}
                    onChange={(e) => setSongDescription(e.target.value)}
                    className="form-control"
                    placeholder="A song about me, myself and my beautiful crypto"
                    maxLength={200}
                    style={{
                      display: "block",
                      marginTop: "0.5rem",
                      width: "100%",
                      height: 80,
                    }}
                  />
                </div>

                <div className="form-group m-t">
                  <label
                    htmlFor="songName"
                    className="form-control-label"
                    style={{ display: "block" }}
                  >
                    Song Name (Optional)
                  </label>
                  <input
                    type="text"
                    id="songName"
                    value={songName}
                    onChange={(e) => setSongName(e.target.value)}
                    className="form-control"
                    placeholder="Optional Song Name"
                    style={{
                      display: "block",
                      marginTop: "0.5rem",
                      width: "100%",
                    }}
                  />
                </div>

                <div className="form-group">
                  <label className="switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      checked={isAdvancedMode}
                      onChange={(e) => setIsAdvancedMode(e.target.checked)}
                    />
                    <span className="slider"></span>
                  </label>{" "}
                  Advanced Mode
                </div>

                {isAdvancedMode && (
                  <div className="advanced-controls pad30">
                    <div className="row">
                      <div className="form-group col">
                        <label className="form-control-label">Tempo</label>
                        <div style={{ marginTop: "0.5rem" }}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="tempo"
                              id="slow"
                              value="Slow"
                              checked={tempo === "Slow"}
                              onChange={(e) => setTempo(e.target.value)}
                            />
                            <label className="form-check-label" htmlFor="slow">
                              Slow
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="tempo"
                              id="moderate"
                              value="Moderate"
                              checked={tempo === "Moderate"}
                              onChange={(e) => setTempo(e.target.value)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="moderate"
                            >
                              Moderate
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="tempo"
                              id="fast"
                              value="Fast"
                              checked={tempo === "Fast"}
                              onChange={(e) => setTempo(e.target.value)}
                            />
                            <label className="form-check-label" htmlFor="fast">
                              Fast
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group col">
                        <label
                          htmlFor="emotion"
                          className="form-control-label"
                          style={{ display: "block" }}
                        >
                          Emotion
                        </label>
                        <select
                          id="emotion"
                          value={emotion}
                          onChange={(e) => setEmotion(e.target.value)}
                          className="form-control c-select"
                          style={{ marginTop: "0.5rem" }}
                        >
                          <option value="">Select a Emotion</option>
                          <option value="Happy">Happy</option>
                          <option value="Sad">Sad</option>
                          <option value="Angry">Angry</option>
                          <option value="Relaxing">Relaxing</option>
                          <option value="Energetic">Energetic</option>
                          <option value="Romantic">Romantic</option>
                          <option value="Melancholic">Melancholic</option>
                          <option value="Inspirational">Inspirational</option>
                        </select>
                      </div>
                    </div>

                    <div className="row m-t">
                      <div className="form-group col">
                        <label
                          htmlFor="genre"
                          className="form-control-label"
                          style={{ display: "block" }}
                        >
                          Genre
                        </label>
                        <select
                          id="genre"
                          value={genre}
                          onChange={(e) => setGenre(e.target.value)}
                          className="form-control c-select"
                          style={{ marginTop: "0.5rem" }}
                        >
                          <option value="">Select a Genre</option>
                          <option value="Pop">Pop</option>
                          <option value="Rock">Rock</option>
                          <option value="Jazz">Jazz</option>
                          <option value="Classical">Classical</option>
                          <option value="Electronic">Electronic</option>
                          <option value="Hip Hop">Hip Hop</option>
                          <option value="Rap">Rap</option>
                          <option value="Blues">Blues</option>
                          <option value="R&B">R&B</option>
                          <option value="Soul">Soul</option>
                          <option value="Country">Country</option>
                          <option value="Reggae">Reggae</option>
                          <option value="Folk">Folk</option>
                          <option value="World Music">World Music</option>
                          <option value="Indie">Indie</option>
                          <option value="Ambient">Ambient</option>
                          <option value="Dance">Dance</option>
                        </select>
                      </div>

                      <div className="form-group col">
                        <label
                          htmlFor="key"
                          className="form-control-label"
                          style={{ display: "block" }}
                        >
                          Key
                        </label>
                        <select
                          id="key"
                          value={key}
                          onChange={(e) => setKey(e.target.value)}
                          className="form-control c-select"
                          style={{ marginTop: "0.5rem" }}
                        >
                          <option value="random">Random</option>
                          <option value="C major">C major</option>
                          <option value="G major">G major</option>
                          <option value="D major">D major</option>
                          <option value="A major">A major</option>
                          <option value="E major">E major</option>
                          <option value="B major">B major</option>
                          <option value="F♯ major">F♯ major</option>
                          <option value="D♭ major">D♭ major</option>
                          <option value="A♭ major">A♭ major</option>
                          <option value="E♭ major">E♭ major</option>
                          <option value="B♭ major">B♭ major</option>
                          <option value="F major">F major</option>
                          <option value="A minor">A minor</option>
                          <option value="E minor">E minor</option>
                          <option value="B minor">B minor</option>
                          <option value="F♯ minor">F♯ minor</option>
                          <option value="C♯ minor">C♯ minor</option>
                          <option value="A♭ minor">A♭ minor</option>
                          <option value="E♭ minor">E♭ minor</option>
                          <option value="B♭ minor">B♭ minor</option>
                          <option value="F minor">F minor</option>
                          <option value="C minor">C minor</option>
                          <option value="G minor">G minor</option>
                          <option value="D minor">D minor</option>
                        </select>
                      </div>
                    </div>

                    <div className="row m-t hide">
                      <div className="form-group col-6">
                        <label
                          htmlFor="instrument"
                          className="form-control-label"
                          style={{ display: "block" }}
                        >
                          Primary Instrument (Up to 3)
                        </label>

                        <MultiSelect
                          id="instrument"
                          options={instrumentOptions}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="pad60"></div>
                <button
                  className="btn btn-fw primary"
                  style={{ width: "100%" }}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <>Submitting...</> : <>Create</>}
                </button>
              </form>

              <div className="pad60"></div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mid">
              <div className="p-t"></div>
              Generated Songs
              <div className="pad10"></div>
              <div className="row item-list item-list-md m-b">
                {createdSongs.map((__item, index) => {
                  return (
                    <div className="col-sm-12" key={__item.song_id}>
                      <div
                        className="item r"
                        data-id="item-5"
                        data-src={__item.audio_url}
                        onClick={() => showDetails(__item)}
                      >
                        <div className="item-media ">
                          <a
                            href="#"
                            className="item-media-content"
                            style={{ backgroundImage: `url(${__item.cover_url ? __item.cover_url : Cover})` }}
                            onClick={(e) => {
                              e.preventDefault();
                              handleSongClick(__item);
                            }}
                          ></a>
                          {__item.status == "completed" && (
                            <div className="item-overlay center">
                              <button
                                className="btn-playpause"
                                onClick={() => handleSongClick(__item)}
                              >
                                <span className="icon-wrapper">
                                  <Play
                                    size={24}
                                    className="play-icon"
                                    style={{ paddingLeft: 3, paddingTop: 3 }}
                                  />
                                </span>
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="item-info">
                          <div
                            className={`item-overlay bottom text-right ${
                              __item.status == "completed" ? "" : "hide"
                            }`}
                            style={{ opacity: 1 }}
                          >
                            <LikeSong
                              liked={__item.liked}
                              songId={__item.song_id}
                              postUpdate={() =>
                                postLikeProcessing(__item.song_id)
                              }
                            />
                            <AudioOption
                              song={__item}
                              placement="bottom-end"
                              isOwned
                            />
                          </div>
                          <div className="item-title text-ellipsis">
                            <a href="#">{__item.title}</a>
                          </div>
                          <div className="item-author text-sm">
                            <span className="text-muted">{__item.prompt}</span>

                            {__item.status == "completed" && (
                              <>
                                <br />
                                <br />
                                <Headphones size={10} />
                                &nbsp;&nbsp;
                                <span className="text-muted">
                                  {__item.play_count}
                                </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Heart size={10} />
                                &nbsp;&nbsp;
                                <span className="text-muted">
                                  {__item.like_count ? __item.like_count : "0"}
                                </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <ToggleSwitch
                                  checked={__item.is_public}
                                  onChange={() =>
                                    handleMakePublic(
                                      __item.song_id,
                                      !__item.is_public,
                                    )
                                  }
                                  label="Public"
                                  mini
                                />
                                {__item.is_public ? (
                                  <ShareOption song={__item} />
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </div>

                          {__item.status == "submitted" && (
                            <div className="p-t">
                              <span className="label rounded primary">
                                Processing
                              </span>
                            </div>
                          )}
                          {__item.status == "error" && (
                            <div className="p-t">
                              <span className="label rounded danger">
                                Error: Please make sure description does not contain artist name. 
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="p-b"></div>
              <div className="pad60"></div>
            </div>
            <div className="col-md-4 right d-none d-lg-block padding">
              {showSongDetails && <div className="item-media ">
                <div
                  className="item-media-content"
                  style={{ backgroundImage: `url(${showSongDetails.cover_url ? showSongDetails.cover_url : Cover})` }}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                ></div>
              </div>}

              {showSongDetails && <h2>{showSongDetails.title}</h2>}
              {showSongDetails && <Lyrics lyrics={showSongDetails.lyric} />}

            </div>
              }
          </div>
        </div>
      </Content>
    </ProtectedPageProvider>
  );
};

export default Create;
