import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import {
  TrendingUp,
  Terminal,
  PlayCircle,
  BarChart2,
  List,
  Music,
  Sliders,
  Search,
  PlusCircle,
} from "react-feather";

import { GlobalContext } from "../../providers/UserContextProvider"; // Adjust path as necessary

import * as keys from "../../utils/keys";
import { Buttons, Social } from "../../components";
import Icons from "../../components/Icons";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useCustomWeb3React } from "../../providers/CustomWeb3ReactProvider";
import { usePartyModeManager } from "../../state/user/hooks";
import { useSideMenu } from "../../state/application/hooks";

import Logo from "../../assets/images/logo.png";

import { HeaderTag, Container, ConnectButton } from "./styled";

const SideMenu = () => {
  const { user, signIn } = useContext(GlobalContext);
  const { isSideMenuOpen, toggleMenu } = useSideMenu();

  const { activate, account } = useCustomWeb3React();
  const { width } = useWindowDimensions();

  return (
    <div
      id="aside"
      className={`app-aside modal fade nav-dropdown ${
        isSideMenuOpen ? "in" : ""
      }`}
    >
      <div className="left navside grey dk" data-layout="column">
        <div className="navbar no-radius">
          <Link to="/" className="navbar-brand md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              width="32"
              height="32"
            >
              <circle cx="24" cy="24" r="24" fill="rgba(255,255,255,0.2)" />
              <circle
                cx="24"
                cy="24"
                r="22"
                fill="#1c202b"
                className="brand-color"
              />

              <g
                transform="translate(13,38) scale(0.0300000,-0.0300000)"
                fill="#1ed891"
                stroke="none"
              >
                <path
                  d="M0 641 c0 -256 2 -296 19 -350 37 -121 110 -204 227 -258 52 -25 71
                    -28 160 -28 95 0 103 2 176 37 91 45 147 103 191 196 29 61 32 75 32 167 0 87
                    -4 107 -26 155 -41 85 -84 137 -149 179 -78 51 -170 75 -252 68 -95 -8 -118
                    -19 -118 -60 l0 -34 55 0 c83 0 116 -28 199 -170 79 -135 92 -191 61 -258 -49
                    -102 -203 -119 -274 -29 -25 31 -22 38 30 64 37 19 50 42 30 54 -5 3 -30 -6
                    -56 -20 -31 -18 -50 -23 -56 -17 -7 7 7 21 40 41 35 22 49 36 45 47 -8 21 -20
                    19 -69 -10 -39 -22 -55 -24 -55 -4 0 4 20 19 45 33 25 15 45 31 45 37 0 20
                    -29 20 -69 -1 -23 -11 -46 -18 -51 -15 -19 12 -31 84 -20 123 17 61 25 69 49
                    47 12 -10 24 -15 27 -12 4 4 -1 9 -10 13 -12 5 -16 19 -16 59 0 51 -2 55 -52
                    103 -95 88 -145 132 -152 132 -3 0 -6 -130 -6 -289z"
                />
              </g>

              <circle
                cx="11"
                cy="11"
                r="2"
                fill="#ffffff"
                className="brand-animate"
              ></circle>
            </svg>
            <span className="hidden-folded inline">BeatFi.</span>
          </Link>
        </div>
        <div data-flex className="hide-scroll">
          <nav className="scroll nav-stacked nav-active-primary">
            <ul className="nav" data-ui-nav>
              <li className="nav-header hidden-folded m-t">
                <span className="text-sm text-muted">Main</span>
              </li>
              <li>
                <NavLink
                  to="/discover"
                  activeclassname="active"
                  onClick={toggleMenu}
                >
                  <span className="nav-icon">
                    <PlayCircle size={18} />
                  </span>
                  <span className="nav-text">Discover</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/chart"
                  activeclassname="active"
                  onClick={toggleMenu}
                >
                  <span className="nav-icon">
                    <TrendingUp size={18} />
                  </span>
                  <span className="nav-text">Charts</span>
                </NavLink>
              </li>

              {user && (
                <>
                  <li className="nav-header hidden-folded m-t">
                    <span className="text-sm text-muted">Your Music</span>
                  </li>
                  <li>
                    <NavLink
                      to="/create"
                      activeclassname="active"
                      onClick={toggleMenu}
                    >
                      <span className="nav-icon">
                        <Terminal size={18} />
                      </span>
                      <span className="nav-text">Create</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/profile"
                      activeclassname="active"
                      onClick={toggleMenu}
                    >
                      <span className="nav-icon">
                        <Music size={18} />
                      </span>
                      <span className="nav-text">Profile</span>
                    </NavLink>
                  </li>
                </>
              )}

              {!user && account && (
                <li className="m-t">
                  <button
                    style={{ width: "100%" }}
                    className="btn btn-fw primary"
                    onClick={signIn}
                  >
                    Sign In
                  </button>
                </li>
              )}

              <li className="m-t">
                <Link
                  to="https://app.uniswap.org/swap?outputCurrency=0xc26C84C7C434cD753fF970aE6811F28D587Bf9e5&chain=ethereum"
                  target="_blank"
                  style={{ width: "100%", backgroundColor: "#000" }}
                  className="btn btn-fw primary text-center"
                >
                  <span className="animate-gradient">Buy $BEATFI</span>
                </Link>
              </li>

              <li className="m-b">
                <Link
                  to="https://www.beatfi.ai"
                  target="_blank"
                  style={{ width: "100%", backgroundColor: "#000" }}
                  className="btn btn-fw primary text-center"
                >
                  <span>Website</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
