import { useMemo, useEffect, useState } from 'react'
import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';
import styled from "styled-components/macro";
import { ChevronRight } from 'react-feather'
import { useNavigate } from 'react-router-dom';

import { useToggleWalletModal } from "../../state/application/hooks";
import { useAllTransactions } from '../../state/transactions/hooks'
import AuthenticatedHeader from "./AuthenticatedHeader";
import { MenuState } from "./index";
import TransactionDetails from '../../components/TransactionDetails'
import { useFinapeNftContract } from "../../hooks/useContract";
import { formatBigNumber } from "../../utils/currency";

const ConnectButton = styled.button`
  border-radius: 12px;
  height: 44px;
  width: 288px;
  font-weight: 600;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  background-color: #157bef;
  color: #fff;
  border: none;

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    width: 100%;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.backgroundOutline};
  margin-top: 26px;
  margin-bottom: 16px;
`

const ToggleMenuItem = styled.button`
  background-color: transparent;
  margin: 0;
  border: none;
  cursor: pointer;
  display: flex;
  flex: 1;
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  padding: 12px 8px;
  color: ${({ theme }) => theme.textSecondary};
  :hover {
    color: ${({ theme }) => theme.textPrimary};
    background-color: ${({ theme }) => theme.backgroundModule};
    transition: ${({
      theme: {
        transition: { duration, timing },
      },
    }) => `${duration.fast} all ${timing.in}`};
  }
`

const FlexContainer = styled.div`
  display: flex;
`

const LatestPendingTxnBox = styled(FlexContainer)`
  display: flex;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.backgroundModule};
  align-items: center;
  gap: 8px;
`

const PendingBadge = styled.span`
  background-color: #41da7e;
  color: #000;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 14px;
`

const IconWrap = styled.span`
  display: inline-block;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 4px;
  height: 16px;
`

const DefaultMenuWrap = styled.div`
  width: 100%;
  height: 100%;
`;

const DefaultText = styled.span`
  font-size: 14px;
  font-weight: 400;
`


const WalletDropdown = ({ setMenu }) => {
  const { account } = useCustomWeb3React();
  const isAuthenticated = !!account;
  const allTransactions = useAllTransactions()
  const toggleWalletModal = useToggleWalletModal();
  const navigate = useNavigate();

  const [nftOwned, setNftOwned] = useState(0);

  const pendingTransactions = useMemo(
    () => Object.values(allTransactions).filter((tx) => !tx.receipt),
    [allTransactions]
  )
  const latestPendingTransaction =
    pendingTransactions.length > 0
      ? pendingTransactions.sort((tx1, tx2) => tx2.addedTime - tx1.addedTime)[0]
      : undefined

  const finapeNftContract = useFinapeNftContract();

  useEffect(() => {
    const fetchData = async () => {
      let _balanceOf = await finapeNftContract.balanceOf(account);
      setNftOwned(parseFloat(formatBigNumber(_balanceOf, 0)).toFixed(0));
    };

    if (finapeNftContract && account) {
      fetchData();
    }
    if (!account) {
      setNftOwned(0);
    }
  }, [finapeNftContract, account]);

  return (
    <DefaultMenuWrap>
      {!isAuthenticated ? (
        <ConnectButton
          data-testid="wallet-connect-wallet"
          onClick={toggleWalletModal}
        >
          Connect wallet
        </ConnectButton>
      ) : (
        <>
          <AuthenticatedHeader />
          <Divider />
          <ToggleMenuItem data-testid="wallet-transactions" onClick={() => setMenu(MenuState.TRANSACTIONS)}>
            <DefaultText>
              <span>Transactions</span>{' '}
              {pendingTransactions.length > 0 && (
                <PendingBadge>
                  {pendingTransactions.length} <span>Pending</span>
                </PendingBadge>
              )}
            </DefaultText>
            <IconWrap>
              <ChevronRight size={16} strokeWidth={3} />
            </IconWrap>
          </ToggleMenuItem>
          {!!latestPendingTransaction && (
            <LatestPendingTxnBox>
              <TransactionDetails
                key={latestPendingTransaction.hash}
                transactionDetails={latestPendingTransaction}
                isLastTransactionInList={true}
              />
            </LatestPendingTxnBox>
          )}
          <ToggleMenuItem className="hide" data-testid="my-nfts" onClick={() => navigate('/nfts')}>
            <DefaultText>
              <span>NFTs</span>{' '}
              {nftOwned > 0 && (
                <PendingBadge>
                  {nftOwned}
                </PendingBadge>
              )}
            </DefaultText>
            <IconWrap>
              <ChevronRight size={16} strokeWidth={3} />
            </IconWrap>
          </ToggleMenuItem>
        </>
      )}
    </DefaultMenuWrap>
  );
};

export default WalletDropdown;
