import { DEFAULT_TXN_DISMISS_MS } from '../../constants/misc';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../state/hooks';

import {
  addPopup,
  ApplicationModal,
  PopupContent,
  removePopup,
  setOpenModal,
  toggleSideMenu,
} from './reducer';

export const useSideMenu = () => {
  const dispatch = useAppDispatch();
  const isSideMenuOpen = useAppSelector(state => state.application.isSideMenuOpen);

  const toggleMenu = () => {
    dispatch(toggleSideMenu());
  };

  return { isSideMenuOpen, toggleMenu };
};

export function useModalIsOpen(modal) {
  const openModal = useAppSelector((state) => state.application.openModal);
  return openModal === modal;
}

export function useToggleModal(modal) {
  const isOpen = useModalIsOpen(modal);
  const dispatch = useAppDispatch();
  return useCallback(() => dispatch(setOpenModal(isOpen ? null : modal)), [dispatch, modal, isOpen]);
}

export function useCloseModal() {
  const dispatch = useAppDispatch();
  return useCallback(() => dispatch(setOpenModal(null)), [dispatch]);
}

export function useOpenModal(modal) {
  const dispatch = useAppDispatch();
  return useCallback(() => dispatch(setOpenModal(modal)), [dispatch, modal]);
}

export function useToggleNowPlayingModal() {
  return useToggleModal(ApplicationModal.NOW_PLAYING);
}

export function useToggleProfileModal() {
  return useToggleModal(ApplicationModal.PROFILE);
}

export function useToggleWalletModal() {
  return useToggleModal(ApplicationModal.WALLET);
}

export function useToggleWalletDropdown() {
  return useToggleModal(ApplicationModal.WALLET_DROPDOWN);
}

export function useToggleSettingsMenu() {
  return useToggleModal(ApplicationModal.SETTINGS);
}

export function useShowClaimPopup() {
  return useModalIsOpen(ApplicationModal.CLAIM_POPUP);
}

export function useToggleShowClaimPopup() {
  return useToggleModal(ApplicationModal.CLAIM_POPUP);
}

export function useToggleSelfClaimModal() {
  return useToggleModal(ApplicationModal.SELF_CLAIM);
}

export function useToggleDelegateModal() {
  return useToggleModal(ApplicationModal.DELEGATE);
}

export function useToggleVoteModal() {
  return useToggleModal(ApplicationModal.VOTE);
}

export function useToggleQueueModal() {
  return useToggleModal(ApplicationModal.QUEUE);
}

export function useToggleExecuteModal() {
  return useToggleModal(ApplicationModal.EXECUTE);
}

export function useTogglePrivacyPolicy() {
  return useToggleModal(ApplicationModal.PRIVACY_POLICY);
}

export function useToggleFeatureFlags() {
  return useToggleModal(ApplicationModal.FEATURE_FLAGS);
}

// returns a function that allows adding a popup
export function useAddPopup() {
  const dispatch = useAppDispatch();

  return useCallback(
    (content, key, removeAfterMs) => {
      dispatch(addPopup({ content, key, removeAfterMs: removeAfterMs ?? DEFAULT_TXN_DISMISS_MS }));
    },
    [dispatch]
  );
}

// returns a function that allows removing a popup via its key
export function useRemovePopup() {
  const dispatch = useAppDispatch();
  return useCallback(
    (key) => {
      dispatch(removePopup({ key }));
    },
    [dispatch]
  );
}

// get the list of active popups
export function useActivePopups() {
  const list = useAppSelector((state) => state.application.popupList);
  return useMemo(() => list.filter((item) => item.show), [list]);
}
