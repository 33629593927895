import { useState } from 'react';
import styled from 'styled-components/macro';

import { useModalIsOpen } from '../../state/application/hooks';
import { ApplicationModal } from '../../state/application/reducer';
import { Z_INDEX } from '../../theme/zIndex';
import DefaultMenu from './DefaultMenu';
import { TransactionHistoryMenu } from './TransactionMenu'

const WalletWrapper = styled.div`
  border-radius: 12px;
  width: 320px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  top: 60px;
  right: 70px;
  background-color: ${({ theme }) => theme.backgroundSurface};
  border: ${({ theme }) => `1px solid ${theme.backgroundOutline}`};
  box-shadow: ${({ theme }) => theme.deepShadow};
  padding: 16px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: 100%;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: unset;
  }
`;

export const MenuState = {
  DEFAULT: 'DEFAULT',
  NFTS: 'NFTS',
  TRANSACTIONS: 'TRANSACTIONS',
};

const WalletDropdownWrapper = styled.div`
  position: fixed;
  top: 72px;
  right: 20px;
  z-index: 1060;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    top: unset;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const WalletDropdown = () => {
  const [menu, setMenu] = useState(MenuState.DEFAULT);
  const walletDropdownOpen = useModalIsOpen(ApplicationModal.WALLET_DROPDOWN);
  return (
    <>
      {walletDropdownOpen && (
        <WalletDropdownWrapper>
          <WalletWrapper>
            {menu === MenuState.TRANSACTIONS && <TransactionHistoryMenu onClose={() => setMenu(MenuState.DEFAULT)} />}
            {menu === MenuState.DEFAULT && <DefaultMenu setMenu={setMenu} />}
          </WalletWrapper>
        </WalletDropdownWrapper>
      )}
    </>
  );
};

export default WalletDropdown;
