import React, { useState } from 'react';
// Make sure to import your CSS for styling

const MultiSelect = ({ options }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectionChange = (event) => {
    const value = event.target.value;
    let newSelection = [...selectedOptions];
    const index = newSelection.indexOf(value);

    if (index > -1) {
      // Option is already selected, remove it
      newSelection.splice(index, 1);
    } else {
      // Add new option if it doesn't exceed the limit
      if (newSelection.length < 3) {
        newSelection.push(value);
      } else {
        // Optionally, alert the user that the limit has been reached
        alert("You can select up to 3 options only.");
      }
    }

    setSelectedOptions(newSelection);
  };

  return (
    <div className="form-control c-select">
      {options.map(option => (
        <label key={option.value} className="custom-option">
          <input
            type="checkbox"
            value={option.value}
            checked={selectedOptions.includes(option.value)}
            onChange={handleSelectionChange}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default MultiSelect;
