import { useEffect, useState, useRef, useContext } from "react";
import { Heart } from "react-feather";

import { makeAuthenticatedApiCall } from "../../utils/user";
import { GlobalContext } from "../../providers/UserContextProvider";

const LikeSong = ({ liked = false, songId, postUpdate, size = 14 }) => {
  const { user } = useContext(GlobalContext);

  const handleLikeSong = async () => {
    try {
      const response = await makeAuthenticatedApiCall(
        "/beat/" + songId + "/like",
        "post",
      );
      //console.log('Submission success:', response.data);
      //console.log('Submission resp:', response);
      if (response.status === 200) {
        //reload data
        if (postUpdate) postUpdate();
      }
    } catch (error) {
      console.error("Submission failed:", error);
    }
  };

  const handleUnlikeSong = async () => {
    try {
      const response = await makeAuthenticatedApiCall(
        "/beat/" + songId + "/unlike",
        "post",
      );
      //console.log('Submission success:', response.data);
      //console.log('Submission resp:', response);
      if (response.status === 200) {
        //reload data
        if (postUpdate) postUpdate();
      }
    } catch (error) {
      console.error("Submission failed:", error);
    }
  };

  if (!user || !songId) {
    return <></>;
  } else {
    return (
      <>
        {liked ? (
          <a
            href="#"
            className="btn-favorite"
            onClick={(e) => {e.preventDefault(); handleUnlikeSong();}}
          >
            <Heart size={size} className="svg-icon icon-on" />
          </a>
        ) : (
          <a
            href="#"
            className="btn-favorite"
            onClick={(e) => {e.preventDefault(); handleLikeSong();}}
          >
            <Heart size={size} className="svg-icon" />
          </a>
        )}
      </>
    );
  }
};

export default LikeSong;
