// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';

import { useManagePlaylist } from '../state/player/hooks'; 
import { useCustomWeb3React } from "../providers/CustomWeb3ReactProvider";

export const ProtectedPageContext = createContext();

export default function ProtectedPageProvider({ children }) {
  const navigate = useNavigate();
  const { handleClearPlaylist } = useManagePlaylist();
  const { account } = useCustomWeb3React(); 
  const [ previousAccount, setPreviousAccount ] = useState(account);

  useEffect(() => {
    Auth.currentAuthenticatedUser().catch(() => {
      navigate('/discover'); // Redirect if not authenticated
    });
  }, [navigate]);

  useEffect(() => {
    const _signout = async () => {
      //handleClearPlaylist();
      navigate('/discover');
    }

    if (previousAccount && (account !== previousAccount || !account)) {
      _signout();
    }
    setPreviousAccount(account); // Update the previous account state
  }, [account, previousAccount]);

  return (
    <ProtectedPageContext.Provider value={{ }}>
      {children}
    </ProtectedPageContext.Provider>
  );
}
