import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { WalletConnect } from "@web3-react/walletconnect";
import { getAddress, signTransaction, signMessage } from "sats-connect";
import { Connection, PublicKey, clusterApiUrl } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { ArrowLeft } from "react-feather";
import { Auth } from 'aws-amplify';
import { ethers } from 'ethers'

import Option from './Option';
import { useCustomWeb3React } from "../../providers/CustomWeb3ReactProvider";
import { GlobalContext } from '../../providers/UserContextProvider';

import {
  getConnection,
  getConnectionName,
  networkConnection,
  ConnectionType,
} from "../../connection";
import {
  getIsCoinbaseWallet,
  getIsInjected,
  getIsMetaMaskWallet,
} from "../../connection/utils";
import { isSupportedChain, SupportedChainId } from "../../constants/chains";
import { updateConnectionError } from "../../state/connection/reducer";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import {
  updateSelectedWallet,
  updateBitcoinWallet,
  updateSolanaWallet,
  disconnectSolanaWallet,
} from "../../state/user/reducer";
import { useConnectedWallets } from "../../state/wallets/hooks";
import { useSelectedNetwork, useCognitoManager } from "../../state/user/hooks";

import { ApplicationModal } from "../../state/application/reducer";
import AccountDetails from "../AccountDetails";
import Modal from "../Modal";

import {
  useModalIsOpen,
  useToggleWalletModal,
} from "../../state/application/hooks";
import { isMobile } from "../../utils/userAgent";
import usePrevious from "../../hooks/usePrevious";
import {
  CoinbaseWalletOption,
  OpenCoinbaseWalletOption,
} from "./CoinbaseWalletOption";
import {
  InjectedOption,
  InstallMetaMaskOption,
  MetaMaskOption,
} from "./InjectedOption";
import PendingView from "./PendingView";
import { WalletConnectOption } from "./WalletConnectOption";
import { WalletConnectV2Option } from "./WalletConnectV2Option";
import { UnisatOption } from "./UnisatOption";
import { XverseOption } from "./XverseOption";
import { PhantomOption } from "./PhantomOption";
import { SolflareOption } from "./SolflareOption";
import { flexColumnNoWrap, flexRowNoWrap } from "../../theme/styles";

import { ReactComponent as Close } from "../../assets/svg/x.svg";

import { checkUser, handleAmplifySignIn } from '../../utils/user';

const AutoColumn = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: ${({ gap, theme }) => (gap && theme.grids[gap]) || gap};
  justify-items: ${({ justify }) => justify && justify};
`;

const AutoRow = styled.div`
  flex-wrap: wrap;
  margin: ${({ gap }) => gap && `-${gap}`};
  justify-content: ${({ justify }) => justify && justify};

  & > * {
    margin: ${({ gap }) => gap} !important;
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: ${({ theme }) => theme.opacity.hover};
  }
`;

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.deprecated_text4};
  }
`;

const Wrapper = styled.div`
  ${flexColumnNoWrap};
  background-color: ${({ theme }) => theme.backgroundSurface};
  outline: ${({ theme }) => `1px solid ${theme.backgroundOutline}`};
  box-shadow: ${({ theme }) => theme.deepShadow};
  margin: 0;
  padding: 0;
  width: 100%;
`;

const HeaderRow = styled.div`
  ${flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 600;
  size: 16px;
  color: ${(props) =>
    props.color === "blue" ? ({ theme }) => theme.accentAction : "inherit"};
  @media (max-width: 768px) {
    padding: 1rem;
  } ;
`;

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.backgroundSurface};
  padding: 0 1rem 1rem 1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  @media (max-width: 768px) {
    padding: 0 1rem 1rem 1rem;
  } ;
`;

const UpperSection = styled.div`
  position: relative;
  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }
  h5:last-child {
    margin-bottom: 0px;
  }
  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`;

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  } ;
`;

const HoverText = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.textPrimary};
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const WALLET_VIEWS = {
  OPTIONS: "options",
  ACCOUNT: "account",
  PENDING: "pending",
};

export default function WalletModal({
  pendingTransactions,
  confirmedTransactions,
  ENSName,
}) {
  const dispatch = useAppDispatch();
  const { connector, account, chainId, provider } = useCustomWeb3React();
  const previousAccount = usePrevious(account);
  const [phantomProvider, setPhantomProvider] = useState(null);
  const { select, wallets, publicKey, connected } = useWallet();

  const { user, setUser } = useContext(GlobalContext);

  const selectedNetwork = useSelectedNetwork();

  const location = useLocation();
  const navigate = useNavigate();

  const [connectedWallets, addWalletToConnectedWallets] = useConnectedWallets();

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT);
  const [lastActiveWalletAddress, setLastActiveWalletAddress] =
    useState(account);

  const [pendingConnector, setPendingConnector] = useState();
  const pendingError = useAppSelector((state) =>
    pendingConnector
      ? state.connection.errorByConnectionType[
          getConnection(pendingConnector).type
        ]
      : undefined,
  );

  const walletModalOpen = useModalIsOpen(ApplicationModal.WALLET);
  const toggleWalletModal = useToggleWalletModal();

  const openOptions = useCallback(() => {
    setWalletView(WALLET_VIEWS.OPTIONS);
  }, [setWalletView]);

  useEffect(() => {
    if (walletModalOpen) {
      setWalletView(account ? WALLET_VIEWS.ACCOUNT : WALLET_VIEWS.OPTIONS);
    }
  }, [walletModalOpen, setWalletView, account]);

  useEffect(() => {
    if (account && account !== previousAccount && walletModalOpen) {
      toggleWalletModal();
      if (location.pathname === "/") {
        //navigate("/minting");
      }
    }
  }, [
    account,
    previousAccount,
    toggleWalletModal,
    walletModalOpen,
    location.pathname,
    navigate,
  ]);

  useEffect(() => {
    const loginCognito = async () => {

      try {
        // We connect to the blockchain and get wallet address and chain ID
        const address = account.toLowerCase();

        // We signin or sigup the user. See utils/user.js
        const cognitoUser = await handleAmplifySignIn(address);
        // console.log("user: ", cognitoUser);

        // We get the message to sign
        const message = cognitoUser.challengeParam.message;
        // console.log("Message to sign: " + message);

        const signature = await provider.getSigner().signMessage(message);

        //console.log("Signature:", signature, message);
        // Send signature and message to your backend for verification

        // We send the signature back to Cognito to complete the authentication process.
        await Auth.sendCustomChallengeAnswer(cognitoUser, signature)
          .catch((err) => {
            console.log(err);
            throw err;
          });

        if (!(await checkUser(setUser))) {
          throw 'Authentication failed';
        } else {
          //const connectionType = getConnection(pendingConnector).type;
          //dispatch(updateSelectedWallet({ wallet: connectionType }));
        }
      } catch (error) {
        console.error("Error signing message:", error);
      }
    }

    if (provider && account && !user) {
      loginCognito();
    }
  }, [
    account,
    provider,
    pendingConnector,
  ]);

  useEffect(() => {
    if (pendingConnector && walletView !== WALLET_VIEWS.PENDING) {
      updateConnectionError({
        connectionType: getConnection(pendingConnector).type,
        error: undefined,
      });
      setPendingConnector(undefined);
    }
  }, [pendingConnector, walletView]);

  useEffect(() => {
    if (pendingConnector && walletView !== WALLET_VIEWS.PENDING) {
      updateConnectionError({
        connectionType: getConnection(pendingConnector).type,
        error: undefined,
      });
      setPendingConnector(undefined);
    }
  }, [pendingConnector, walletView]);

  // Keep the network connector in sync with any active user connector to prevent chain-switching on wallet disconnection.
  useEffect(() => {
    if (
      chainId &&
      chainId !== SupportedChainId.BITCOIN &&
      chainId !== SupportedChainId.SOLANA &&
      isSupportedChain(chainId) &&
      connector !== networkConnection.connector
    ) {
      networkConnection.connector.activate(chainId);
    }
  }, [chainId, connector]);

  // When new wallet is successfully set by the user, trigger logging of Amplitude analytics event.
  useEffect(() => {
    if (
      chainId !== SupportedChainId.BITCOIN &&
      chainId !== SupportedChainId.SOLANA &&
      account &&
      account !== lastActiveWalletAddress
    ) {
      const walletType = getConnectionName(getConnection(connector).type);
      const isReconnect =
        connectedWallets.filter(
          (wallet) =>
            wallet.account === account && wallet.walletType === walletType,
        ).length > 0;
      if (!isReconnect) addWalletToConnectedWallets({ account, walletType });
    }
    setLastActiveWalletAddress(account);
  }, [
    connectedWallets,
    addWalletToConnectedWallets,
    lastActiveWalletAddress,
    account,
    connector,
    chainId,
    provider,
  ]);

  const tryActivation = useCallback(
    async (connector: Connector) => {
      const connectionType = getConnection(connector).type;

      try {
        setPendingConnector(connector);
        setWalletView(WALLET_VIEWS.PENDING);
        dispatch(updateConnectionError({ connectionType, error: undefined }));

        await connector.activate();

        dispatch(updateSelectedWallet({ wallet: connectionType }));
      } catch (error) {
        console.debug(`web3-react connection error: ${error}`);
        dispatch(
          updateConnectionError({ connectionType, error: error.message }),
        );
      }
    },
    [dispatch],
  );

  const selfRef = useRef({
    accounts: [],
  });
  const self = selfRef.current;
  const handleAccountsChanged = async (_accounts) => {
    if (self.accounts[0] === _accounts[0]) {
      // prevent from triggering twice
      return;
    }
    self.accounts = _accounts;
    if (_accounts.length > 0) {
      const publicKey = await unisat.getPublicKey();

      dispatch(
        updateBitcoinWallet({
          accounts: _accounts,
          account: _accounts[0],
          accountPublicKey: publicKey,
        }),
      );
    } else {
    }
  };

  const unisat = window?.unisat;
  const tryActivateUnisat = useCallback(async () => {
    const connectionType = ConnectionType.UNISAT_CONNECT;

    if (!unisat) {
      // show alert about unisat not installed

      alert("Unisat wallet not installed");
      return;
    }

    try {
      dispatch(updateConnectionError({ connectionType, error: undefined }));

      const result = await unisat.requestAccounts();

      handleAccountsChanged(result);

      dispatch(updateSelectedWallet({ wallet: connectionType }));
    } catch (error) {
      //console.debug(`web3-react connection error: ${error}`);
      dispatch(updateConnectionError({ connectionType, error: error.message }));
    }
  }, [dispatch, unisat]);

  const tryActivateXverse = useCallback(async () => {
    const connectionType = ConnectionType.XVERSE_CONNECT;

    try {
      setPendingConnector(connector);
      dispatch(updateConnectionError({ connectionType, error: undefined }));

      const getAddressOptions = {
        payload: {
          purposes: ["ordinals", "payment"],
          message: "Address for receiving Ordinals",
          network: {
            type: "Mainnet",
          },
        },
        onFinish: (response) => {
          dispatch(
            updateBitcoinWallet({
              account: response.addresses[1].address,
              ordinalAddress: response.addresses[0].address,
            }),
          );
          dispatch(updateSelectedWallet({ wallet: connectionType }));

          toggleWalletModal();
        },
        onCancel: () => alert("Request canceled"),
      };
      await getAddress(getAddressOptions);
    } catch (error) {
      //console.debug(`web3-react connection error: ${error}`);
      dispatch(updateConnectionError({ connectionType, error: error.message }));
    }
  }, [dispatch, toggleWalletModal]);

  const getSolanaProvider = () => {
    if ("phantom" in window) {
      const provider = window.phantom?.solana;

      if (provider?.isPhantom) {
        return provider;
      }
    }

    //window.open('https://phantom.app/', 'getPhantom');
  };

  const tryActivatePhantom = useCallback(async () => {
    const connectionType = ConnectionType.PHANTOM_CONNECT;

    const solana = getSolanaProvider();

    if (!solana) {
      // show alert about unisat not installed

      alert("Phantom wallet not installed");
      return;
    }

    try {
      dispatch(updateConnectionError({ connectionType, error: undefined }));

      setPhantomProvider(solana);
      const response = await solana.connect({ onlyIfTrusted: false });

      dispatch(
        updateSolanaWallet({
          account: response.publicKey.toString(),
          accountPublicKey: response.publicKey.toString(),
        }),
      );

      dispatch(updateSelectedWallet({ wallet: connectionType }));
      
    } catch (error) {
      //console.debug(`web3-react connection error: ${error}`);
      dispatch(updateConnectionError({ connectionType, error: error.message }));
    }
  }, [dispatch]);

  useEffect(() => {
    const connectionType = ConnectionType.PHANTOM_CONNECT;

    phantomProvider?.on("connect", (publicKey) => {
      //console.log(`connect event: ${publicKey}`);
      //setConnected(true);
      //setPubKey(publicKey);

    });
    phantomProvider?.on("accountChanged", (publicKey) => {
      if (publicKey) {
        dispatch(
          updateSolanaWallet({
            account: publicKey.toBase58(),
            accountPublicKey: publicKey.toBase58(),
          }),
        );

        dispatch(updateSelectedWallet({ wallet: connectionType }));
      } else {
        // Attempt to reconnect to Phantom
        phantomProvider.connect().catch((error) => {
          // Handle connection failure
        });
      }
    });
    phantomProvider?.on("disconnect", () => {
      //console.log("disconnect event");
      setPhantomProvider(null);
      //setConnected(false);
      //setPubKey(null);
    });
  }, [phantomProvider]);

  const tryActivateSolanaWallet = useCallback(async (adapterName) => {
    const connectionType = ConnectionType.PHANTOM_CONNECT;

    try {
      dispatch(updateConnectionError({ connectionType, error: undefined }));

      select(adapterName)
    } catch (error) {
      //console.debug(`web3-react connection error: ${error}`);
      dispatch(updateConnectionError({ connectionType, error: error.message }));
    }
  }, [dispatch]);

  useEffect(() => {
    const connectionType = ConnectionType.PHANTOM_CONNECT;

    if (connected && publicKey && chainId == SupportedChainId.SOLANA) {
      dispatch(
        updateSolanaWallet({
          account: publicKey.toBase58(),
          accountPublicKey: publicKey.toBase58(),
        }),
      );

      dispatch(updateSelectedWallet({ wallet: connectionType }));
    }

  }, [chainId, connected, publicKey]);

  function getOptions() {
    const isInjected = getIsInjected();
    const hasMetaMaskExtension = getIsMetaMaskWallet();
    const hasCoinbaseExtension = getIsCoinbaseWallet();

    const isCoinbaseWalletBrowser = isMobile && hasCoinbaseExtension;
    const isMetaMaskBrowser = isMobile && hasMetaMaskExtension;
    const isInjectedMobileBrowser =
      isCoinbaseWalletBrowser || isMetaMaskBrowser;

    let injectedOption;
    if (!isInjected) {
      if (!isMobile) {
        injectedOption = <InstallMetaMaskOption />;
      }
    } else if (!hasCoinbaseExtension) {
      if (hasMetaMaskExtension) {
        injectedOption = <MetaMaskOption tryActivation={tryActivation} />;
      } else {
        injectedOption = <InjectedOption tryActivation={tryActivation} />;
      }
    }

    let coinbaseWalletOption;
    if (isMobile && !isInjectedMobileBrowser) {
      coinbaseWalletOption = <OpenCoinbaseWalletOption />;
    } else if (!isMobile || isCoinbaseWalletBrowser) {
      coinbaseWalletOption = (
        <CoinbaseWalletOption tryActivation={tryActivation} />
      );
    }

    const walletConnectionOption =
      (!isInjectedMobileBrowser && (
        <WalletConnectV2Option tryActivation={tryActivation} />
      )) ??
      null;

    const isBitcoin = selectedNetwork == -1;

    const isSolana = selectedNetwork == 501424;

    return (
      <>
        {!isBitcoin && !isSolana && injectedOption}
        {!isBitcoin && !isSolana && coinbaseWalletOption}
        {!isBitcoin && !isSolana && walletConnectionOption}
        {isBitcoin && <UnisatOption tryActivation={tryActivateUnisat} />}
        {isBitcoin && <XverseOption tryActivation={tryActivateXverse} />}
        {isSolana && <PhantomOption tryActivation={tryActivatePhantom} />}
        {false && isSolana && <SolflareOption tryActivation={tryActivatePhantom} />}
        {false && isSolana &&
        wallets.filter((wallet) => wallet.readyState === "Installed").length >
          0 ? (
          wallets
            .filter((wallet) => wallet.readyState === "Installed")
            .map((wallet) => (
              <Option
                color='#4196FC'
                icon={wallet.adapter.icon}
                id={wallet.adapter.name}
                key={wallet.adapter.name}
                //isActive={isActive}
                onClick={() => tryActivateSolanaWallet(wallet.adapter.name)}
                header={wallet.adapter.name}
                />
            ))
        ) : (
          <div>
            {false && "No wallet found. Please download a supported Solana wallet"}
          </div>
        )}
      </>
    );
  }

  function getModalContent() {
    if (false && walletView === WALLET_VIEWS.ACCOUNT) {
      return (
        <AccountDetails
          toggleWalletModal={toggleWalletModal}
          pendingTransactions={pendingTransactions}
          confirmedTransactions={confirmedTransactions}
          ENSName={ENSName}
          openOptions={openOptions}
        />
      );
    }

    let headerRow;
    if (
      walletView === WALLET_VIEWS.PENDING ||
      walletView === WALLET_VIEWS.ACCOUNT ||
      !!account
    ) {
      headerRow = (
        <HeaderRow color="blue">
          <HoverText
            onClick={() =>
              setWalletView(
                account ? WALLET_VIEWS.ACCOUNT : WALLET_VIEWS.OPTIONS,
              )
            }
          >
            <ArrowLeft />
          </HoverText>
        </HeaderRow>
      );
    } else {
      headerRow = (
        <HeaderRow>
          <HoverText>
            <>Connect a wallet</>
          </HoverText>
        </HeaderRow>
      );
    }

    function getTermsOfService(walletView: string) {
      if (walletView === WALLET_VIEWS.PENDING) return null;

      const content = (
        <>
          By connecting a wallet, you agree to Uniswap Labs’{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://uniswap.org/terms-of-service/"
          >
            Terms of Service
          </a>{" "}
          and consent to its{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://uniswap.org/privacy-policy"
          >
            Privacy Policy
          </a>
          .
        </>
      );
      return (
        <AutoRow style={{ flexWrap: "nowrap", padding: "4px 16px" }}>
          <span style={{ fontSize: 16, lineHeight: 24 }}>
            {false && content}
          </span>
        </AutoRow>
      );
    }

    return (
      <UpperSection>
        <CloseIcon data-testid="wallet-modal-close" onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>{" "}
        -{headerRow}
        <ContentWrapper>
          <AutoColumn gap="16px">
            {walletView === WALLET_VIEWS.PENDING && pendingConnector && (
              <PendingView
                openOptions={openOptions}
                connector={pendingConnector}
                error={!!pendingError}
                tryActivation={tryActivation}
              />
            )}
            {walletView !== WALLET_VIEWS.PENDING && (
              <OptionGrid data-testid="option-grid">{getOptions()}</OptionGrid>
            )}
            {!pendingError && getTermsOfService(walletView)}
          </AutoColumn>
        </ContentWrapper>
      </UpperSection>
    );
  }

  /**
   * Do not show <WalletModal /> when WalletConnect connection modal is open to prevent focus issues when
   * trying to interact with a WalletConnect modal.
   */
  const isWalletConnectModalOpen =
    walletView === WALLET_VIEWS.PENDING &&
    pendingConnector instanceof WalletConnect &&
    !pendingError;

  return (
    <Modal
      isOpen={walletModalOpen && !isWalletConnectModalOpen}
      onDismiss={toggleWalletModal}
      minHeight={false}
      maxHeight={90}
    >
      <Wrapper data-testid="wallet-modal">{getModalContent()}</Wrapper>
      
    </Modal>
  );
}
