import { useEffect, useState, useRef, useContext } from "react";
import {
  Copy,
  Download,
  Plus,
  Play,
  MoreHorizontal,
  ChevronDown,
} from "react-feather";
import {
  EmailShareButton,
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import Popup from "reactjs-popup";

import { useManagePlaylist } from "../../state/player/hooks";

const AudioOption = ({
  song,
  isOwned = false,
}) => {
  const { handleAddSongAndPlay, handleAddSong } = useManagePlaylist();

  const [showTooltip, setShowTooltip] = useState(false);

  if (!song) return null;
  return (
    <Popup
      trigger={
        <MoreHorizontal
          size={14}
          className="svg-icon"
          style={{ marginLeft: 10 }}
        />
      }
      position="bottom right"
      arrow={false}
      offsetX={10}
      offsetY={10}
    >
      <div className="popover-content">
        <a className="hide dropdown-item" href="#">
          <Plus size={14} /> Add to Queque
        </a>
        <a
          className="dropdown-item"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleAddSong(song);
          }}
        >
          <Plus size={14} />
          &nbsp;&nbsp;Add to Playlist
        </a>
        {isOwned && (
          <>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href={song.audio_url} target="_blank">
              <Download size={14} />
              &nbsp;&nbsp;Download
            </a>
          </>
        )}
      </div>
    </Popup>
  );
};

export default AudioOption;
