import { useEffect, useState, useRef, useContext } from "react";
import {
  Copy,
  Download,
  Plus,
  Play,
  MoreHorizontal,
  Share2,
} from "react-feather";
import {
  EmailShareButton,
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import Popup from "reactjs-popup";

import { useManagePlaylist } from "../../state/player/hooks";

const ShareOption = ({ song, isBeatFiSong = false }) => {
  const { handleAddSongAndPlay, handleAddSong } = useManagePlaylist();

  const [showTooltip, setShowTooltip] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText("https://app.beatfi.ai/listen/" + song.song_id)
      .then(() => {
        setShowTooltip(true);
        setTimeout(() => {
          setShowTooltip(false);
        }, 1000); // Hide the tooltip after 1 second
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard", err);
      });
  };

  const message = isBeatFiSong
    ? "Check out this BeatFi music - "
    : "Check out my BeatFi music - ";

  if (!song) return null;
  return (
    <Popup
      trigger={
        <Share2
          size={14}
          className="svg-icon"
          style={{ marginLeft: 10 }}
        />
      }
      position="bottom right"
      arrow={false}
      offsetX={10}
      offsetY={10}
    >
      <div className="popover-content">
        <div className="dropdown-item p-b" href="#">
          <span style={{ fontSize: 14, color: "#fff" }}>Share:</span>
        </div>
        <div className="dropdown-item">
          <EmailShareButton
            subject={message + song.title}
            url={"https://app.beatfi.ai/listen/" + song.song_id}
          >
            <EmailIcon size={28} round={true} />
          </EmailShareButton>
          &nbsp;
          <FacebookShareButton
            hashtag="#BEATFI"
            url={"https://app.beatfi.ai/listen/" + song.song_id}
          >
            <FacebookIcon size={28} round={true} />
          </FacebookShareButton>
          &nbsp;
          <RedditShareButton
            url={"https://app.beatfi.ai/listen/" + song.song_id}
            title={message + song.title}
          >
            <RedditIcon size={28} round={true} />
          </RedditShareButton>
          &nbsp;
          <TelegramShareButton
            url={"https://app.beatfi.ai/listen/" + song.song_id}
            title={message + song.title}
          >
            <TelegramIcon size={28} round={true} />
          </TelegramShareButton>
          &nbsp;
          <TwitterShareButton
            url={"https://app.beatfi.ai/listen/" + song.song_id}
            title={message + song.title}
            hashtags={["BEATFI"]}
            related={["BeatFiAi"]}
          >
            <TwitterIcon size={28} round={true} />
          </TwitterShareButton>
          &nbsp;
          <WhatsappShareButton
            url={"https://app.beatfi.ai/listen/" + song.song_id}
            title={message + song.title}
          >
            <WhatsappIcon size={28} round={true} />
          </WhatsappShareButton>
          &nbsp;
          <button
            className="react-share__ShareButton"
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: 0,
              font: "inherit",
              color: "inherit",
              cursor: "pointer",
            }}
            onClick={handleCopyToClipboard}
          >
            <Copy size={24} stroke="#fff" />
            {showTooltip && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  padding: "5px",
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "14px",
                }}
              >
                Share Link is Copied!
              </div>
            )}
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default ShareOption;
