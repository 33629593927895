import { useCallback, useMemo, useState, useContext } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { Copy, CreditCard, ExternalLink as ExternalLinkIcon, Info, Power, AlertTriangle } from 'react-feather';
import { useWallet } from "@solana/wallet-adapter-react";

import { useCustomWeb3React } from '../../providers/CustomWeb3ReactProvider';

import { getConnection } from '../../connection/utils';
import { getChainInfoOrDefault } from '../../constants/chainInfo';
import { SupportedChainId } from '../../constants/chains';
import useCopyClipboard from '../../hooks/useCopyClipboard';
import { useAppDispatch } from '../../state/hooks';
import { updateSelectedWallet, disconnectBitcoinWallet, disconnectSolanaWallet } from '../../state/user/reducer';

import { shortenAddress } from '../../utils';
import { useCloseModal, useFiatOnrampAvailability, useOpenModal, useToggleModal } from '../../state/application/hooks';
import { ApplicationModal } from '../../state/application/reducer';
import IconButton, { IconHoverText } from './IconButton';
import TempAvatar from "../../assets/icons/icon.png";

import { handleAmplifySignOut } from '../../utils/user';
import { GlobalContext } from '../../providers/UserContextProvider';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  & > a,
  & > button {
    margin-right: 8px;
  }

  & > button:last-child {
    margin-right: 0px;
    ${IconHoverText}:last-child {
      left: 0px;
    }
  }
`;

const TruncatedTextStyle = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const FlexContainer = styled.div`
  ${TruncatedTextStyle}
  padding-right: 4px;
  display: inline-flex;
  margin-top: 5px;
`;

const AccountNamesWrapper = styled.div`
  min-width: 0;
  margin-right: 8px;
`;

const ENSNameContainer = styled.span`
  ${TruncatedTextStyle}
  color: ${({ theme }) => theme.textPrimary};
  margin-top: 2.5px;
`;

const AccountContainer = styled.span`
  ${TruncatedTextStyle}
  color: ${({ theme }) => theme.textSecondary};
  margin-top: 2.5px;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
`;

const NetworkIcon = styled(AlertTriangle)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 24px;
  height: 24px;
`;

const AuthenticatedHeader = () => {
  const { account, chainId, connector, ENSName } = useCustomWeb3React();
  const { user, setUser } = useContext(GlobalContext);

  const { select, disconnect } = useWallet();

  const [isCopied, setCopied] = useCopyClipboard();
  const copy = useCallback(() => {
    setCopied(account || '');
  }, [account, setCopied]);
  const dispatch = useAppDispatch();
  const {
    explorer,
  } = getChainInfoOrDefault(chainId ? chainId : SupportedChainId.MAINNET);

  const disconnectWallet = useCallback(async () => {
    if (connector && connector.deactivate) {
      connector.deactivate();
    }

    if (connector) {
      connector.resetState();
    }

    await handleAmplifySignOut(setUser)
    dispatch(updateSelectedWallet({ wallet: undefined }));

    if (chainId == SupportedChainId.BITCOIN) {
      dispatch(disconnectBitcoinWallet());
    }

    if (chainId == SupportedChainId.SOLANA) {
      dispatch(disconnectSolanaWallet());
      disconnect()
    }
  }, [connector, dispatch]);

  const disconnectSolWallet = () => {
      dispatch(disconnectSolanaWallet());
      disconnect()
  }
  
  return (
    <>
      <HeaderWrapper>
        <FlexContainer>
          {user ? <img src={TempAvatar} alt="avatar" style={{width: 24, height: 24}} /> : <NetworkIcon />}
          {ENSName ? (
            <AccountNamesWrapper>
              <ENSNameContainer>{ENSName}</ENSNameContainer>
              <AccountContainer>{account && shortenAddress(account, 2, 4)}</AccountContainer>
            </AccountNamesWrapper>
          ) : (
            <span style={{paddingLeft: 5, fontSize:18, marginTop:5, fontWeight: 500}}>{account && shortenAddress(account, 2, 4)}</span>
          )}
        </FlexContainer>
        <IconContainer>
          <IconButton onClick={copy} Icon={Copy}>
            {isCopied ? <>Copied!</> : <>Copy</>}
          </IconButton>
          <IconButton href={`${explorer}address/${account}`} target="_blank" Icon={ExternalLinkIcon} style={{color: "#000"}}>
            Explore
          </IconButton>
          <IconButton data-testid="wallet-disconnect" onClick={chainId == SupportedChainId.SOLANA ? disconnectSolWallet : disconnectWallet} Icon={Power}>
            Disconnect
          </IconButton>
        </IconContainer>
      </HeaderWrapper>
    </>
  )
}

export default AuthenticatedHeader
