// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { createContext, useEffect, useState } from "react";
import { Auth } from 'aws-amplify';

import { checkUser, handleAmplifySignOut, handleAmplifySignIn } from "../utils/user";
import { useManagePlaylist } from "../state/player/hooks";
import { useCustomWeb3React } from "../providers/CustomWeb3ReactProvider";

export const GlobalContext = createContext();

export default function ContextProvider({ children }) {
  const { handleClearPlaylist } = useManagePlaylist();
  const { account, provider } = useCustomWeb3React(); // Assume you're using web3-react
  const [previousAccount, setPreviousAccount] = useState(account);
  const [isInitialized, setIsInitialized] = useState(false);

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const _signout = async () => {
      handleClearPlaylist();
      await signOut();
      //window.location.href = '/discover';
    };

    if (previousAccount && (account !== previousAccount || !account)) {
      _signout();
    }

    setPreviousAccount(account); // Update the previous account state
  }, [account, previousAccount]);

  // Check the user once when the app starts
  // Previously logged in users will automatically be logged in
  //useEffect(() => {
  //  checkUser(setUser);
  //}, []);

  useEffect(() => {
    // Check the user once when the app starts
    // Previously logged in users will automatically be logged in
    const fetchUser = async () => {
      setLoading(true);
      try {
        const authenticatedUser = await checkUser(setUser);
        setUser(authenticatedUser);
      } catch (error) {
        console.error("Error fetching user", error);
        setUser(null);
      }
      setLoading(false);
    };

    fetchUser();
  }, []);

  const signIn = async () => {
    if (provider && account && !user) {
      setLoading(true);

      const address = account.toLowerCase();

      const cognitoUser = await handleAmplifySignIn(address);

      // We get the message to sign
      const message = cognitoUser.challengeParam.message;
      // console.log("Message to sign: " + message);

      try {
        const signature = await provider.getSigner().signMessage(message);

        //console.log("Signature:", signature, message);
        // Send signature and message to your backend for verification

        // We send the signature back to Cognito to complete the authentication process.
        await Auth.sendCustomChallengeAnswer(cognitoUser, signature).catch(
          (err) => {
            console.log(err);
            throw err;
          },
        );

        if (!(await checkUser(setUser))) {
          throw "Authentication failed";
        } else {
          //const connectionType = getConnection(pendingConnector).type;
          //dispatch(updateSelectedWallet({ wallet: connectionType }));
        }
      } catch (error) {
        console.error("Error signing message:", error);
      }

      setLoading(false);
    }
  };

  const signOut = async () => {
    setLoading(true);
    try {
      await handleAmplifySignOut(setUser);
    } catch (error) {
      console.error("Error during sign out", error);
    }
    setUser(null); // Ensure user is null after sign out
    setLoading(false);
  };

  return (
    <GlobalContext.Provider value={{ user, setUser, signIn, signOut, loading }}>
      {children}
    </GlobalContext.Provider>
  );
}
