import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  addSong,
  removeSong,
  play,
  stop,
  clearPlaylist,
  playNext,
  playPrevious,
  setCurrentSong,
  addSongAndPlay
} from './reducer';
import { logPlay } from '../../utils/api'; 

export function usePlaylist() {
  const playlist = useAppSelector(state => state.player.playlist);
  return playlist;
}

export function useCurrentSong() {
  // Directly get currentSong from state instead of using index from playlist
  const currentSong = useAppSelector(state => state.player.currentSong);
  return currentSong;
}

export function usePlayerControls() {
  const dispatch = useAppDispatch();
  const currentSong = useAppSelector(state => state.player.currentSong); 

  const handlePlay = useCallback(() => {
    //console.log("handlePlayhandlePlayhandlePlayhandlePlayhandlePlayhandlePlay")
    if (currentSong) {
      logPlay(currentSong.song_id); // Record play when the play button is pressed
      dispatch(play());
    }

    dispatch(play());
  }, [dispatch, currentSong]);

  const handleStop = useCallback(() => {
    dispatch(stop());
  }, [dispatch]);

  const handlePlayNext = useCallback(() => {
    dispatch(playNext());
  }, [dispatch]);

  const handlePlayPrevious = useCallback(() => {
    dispatch(playPrevious());
  }, [dispatch]);
  
  return { handlePlay, handleStop, handlePlayNext, handlePlayPrevious };
}

export function useManagePlaylist() {
  const dispatch = useAppDispatch();

  const handleAddSong = useCallback((song) => {
    dispatch(addSong(song));
  }, [dispatch]);

  const handleRemoveSong = useCallback((song_id) => {
    dispatch(removeSong({ song_id }));
  }, [dispatch]);

  const handleAddSongAndPlay = useCallback((song) => {
    dispatch(addSongAndPlay(song));
  }, [dispatch]);


  const handleClearPlaylist = useCallback(() => {
    dispatch(clearPlaylist());
  }, [dispatch]);

  return { handleAddSong, handleRemoveSong, handleAddSongAndPlay, handleClearPlaylist };
}

export function useIsPlaying() {
  return useAppSelector(state => state.player.isPlaying);
}

export function useSetCurrentSong() {
  const dispatch = useAppDispatch();
  return useCallback((song_id) => {
    dispatch(setCurrentSong({ song_id }));
  }, [dispatch]);
}
