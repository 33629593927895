import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Header from "../../components/Header";
import SideMenu from "../../components/SideMenu";
import AudioPlayer from "../../components/AudioPlayer";

const Wrapper = styled.div``;

const Layout = ({ footer, children, className, id }) => {

  return (
    <Wrapper className="black">
      <div className={className} id={id}>
        <SideMenu />
        <div
          id="content"
          className="app-content white bg box-shadow-z2"
          role="main"
        >
          <Header />
          <AudioPlayer />
          <div className="app-body" id="view">
            {children}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

Layout.defaultProps = {
  header: "",
  footer: "",
  children: "",
};

Layout.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
