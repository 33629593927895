import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

const TabsContainter = styled.div`
  position: relative;

  .tabContainer {
  }
  .tabs {
    display: flex;
    position: relative;
    color: #fff;
    box-shadow: 0 0 1px 0 rgba(#185ee0, 0.15), 0 6px 12px 0 rgba(#185ee0, 0.15);
    * {
      z-index: 2;
    }
  }

  input[type="radio"] {
    display: none;
  }

  .tab {
    text-align: left;
    margin-right: 30px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: color 0.15s ease-in;
  }

  .active-tab {
    border-bottom: 2px solid #1ed891;
    padding-bottom: 2px; 
    color: #1ed891 !important;
  }

  input[type="radio"] {
    &:checked {
      & + label {
        color: #185ee0;
        & > .notification {
          background-color: #185ee0;
          color: #fff;
        }
      }
    }
  }

  .tab-content {
    padding: 10px;
  }

  .tab-pane {
    display: none;
  }

  .tab-pane.active {
    display: block;
  }
`;

const TabBar = ({ tabs, defaultTab, children }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <TabsContainter>
      <div className="tabContainer">
        <div className="tabs">
          {tabs.map((tab, index) => (
            <React.Fragment key={tab}>
              <input
                type="radio"
                id={`tab${index + 1}`}
                name="tabs"
                checked={tab === activeTab}
                onChange={() => handleTabClick(tab)}
              />
              <label htmlFor={`tab${index + 1}`} className={`tab ${tab === activeTab ? "active-tab" : ""}`}>
                {tab}
              </label>
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="tab-content">
        {children.map((child, index) => (
          <div
            key={index}
            className={`tab-pane ${tabs[index] === activeTab ? "active" : ""}`}
          >
            {child}
          </div>
        ))}
      </div>
    </TabsContainter>
  );
};

export default TabBar;
