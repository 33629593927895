import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ArrowLeft } from "react-feather";
import { Auth } from "aws-amplify";

import { useCustomWeb3React } from "../../providers/CustomWeb3ReactProvider";
import { GlobalContext } from "../../providers/UserContextProvider";
import { ApplicationModal } from "../../state/application/reducer";
import Modal from "../Modal";
 
import {
  useModalIsOpen,
  useToggleProfileModal,
} from "../../state/application/hooks";
import { makeAuthenticatedApiCall } from "../../utils/user";
import usePrevious from "../../hooks/usePrevious";

import { flexColumnNoWrap, flexRowNoWrap } from "../../theme/styles";

import { ReactComponent as Close } from "../../assets/svg/x.svg";

import { checkUser, handleAmplifySignIn } from "../../utils/user";

const AutoColumn = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: ${({ gap, theme }) => (gap && theme.grids[gap]) || gap};
  justify-items: ${({ justify }) => justify && justify};
`;

const AutoRow = styled.div`
  flex-wrap: wrap;
  margin: ${({ gap }) => gap && `-${gap}`};
  justify-content: ${({ justify }) => justify && justify};

  & > * {
    margin: ${({ gap }) => gap} !important;
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: ${({ theme }) => theme.opacity.hover};
  }
`;

const CloseColor = styled(Close)`
  stroke: #fff;
  path {
    stroke: #fff;
  }
`;

const Wrapper = styled.div`
  ${flexColumnNoWrap};
  background-color: #000;
  outline: ${({ theme }) => `1px solid ${theme.backgroundOutline}`};
  box-shadow: ${({ theme }) => theme.deepShadow};
  margin: 0;
  padding: 0;
  width: 100%;
`;

const HeaderRow = styled.div`
  ${flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 600;
  size: 16px;
  color: ${(props) =>
    props.color === "blue" ? ({ theme }) => theme.accentAction : "#ccc"};
  @media (max-width: 768px) {
    padding: 1rem;
  } ;
`;

const ContentWrapper = styled.div`
  background-color: #000;
  padding: 0 1rem 1rem 1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  @media (max-width: 768px) {
    padding: 0 1rem 1rem 1rem;
  } ;
`;

const UpperSection = styled.div`
  position: relative;
  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }
  h5:last-child {
    margin-bottom: 0px;
  }
  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`;

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  } ;
`;

const HoverText = styled.div`
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

export default function ProfileModal({onProfileUpdated}) {
  const { connector, account, chainId, provider } = useCustomWeb3React();
  const previousAccount = usePrevious(account);
  const { user, setUser } = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const walletModalOpen = useModalIsOpen(ApplicationModal.PROFILE);
  const toggleProfileModal = useToggleProfileModal();

  const [bio, setBio] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({
    type: "", // 'success' or 'error'
    header: "",
    body: "",
  });

  const fetchUserProfile = async () => {
    try {
      const response1 = await makeAuthenticatedApiCall(
        "/user/profile",
        "get",
      );
      //console.log("API response:", response1);
      setDisplayName(response1.data.display_name || "");
      setBio(response1.data.bio || "");
    } catch (error) {
      console.error("API error:", error);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, [account]);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Simple required field validation
    if (!displayName.trim()) {
      alert("Display name is required."); // Basic alert, consider using a more user-friendly notification method
      return;
    }

    // Prepare the data payload
    var data = {
      display_name: displayName,
      bio: bio,
    };

    setIsSubmitting(true); // Set loading state

    try {
      const response = await makeAuthenticatedApiCall("/user/profile", "put", data);
      //console.log('Submission success:', response.data);
      //console.log('Submission resp:', response);
      if (response.status === 200) {
        setShowMessage(true);
        setMessageContent({
          type: "success",
          header: "Successful",
          body: "Profile update is successful!",
        });

        if (onProfileUpdated) onProfileUpdated(); 

        toggleProfileModal(); 
      } else {
        //console.log(response)
        setShowMessage(true);
        setMessageContent({
          type: "error",
          header: "Update failed",
          body: response?.error?.message || "Please try again later.",
        });
        setTimeout(() => {
          setShowMessage(false); // Hide after 5 seconds
        }, 10000);
      }
    } catch (error) {
      //console.error('Submission failed:', error);

      setShowMessage(true);
      setMessageContent({
        type: "error",
        header: "Submission failed",
        body: "Please try again later.",
      });

    }

    setIsSubmitting(false); // Reset loading state
  };

  function getModalContent() {
    let headerRow = (
      <HeaderRow>
        <HoverText>
          <>Edit Profile</>
        </HoverText>
      </HeaderRow>
    );

    return (
      <UpperSection>
        <CloseIcon onClick={toggleProfileModal}>
          <CloseColor />
        </CloseIcon>{" "}
        {headerRow}
        <ContentWrapper>
          <AutoColumn gap="16px">
            <form onSubmit={handleSubmit}>
              {showMessage && (
                  <div
                    className={`m-b ${
                      messageContent.type === "success" ? "primary" : "danger"
                    }`}
                  >
                    <div className="box-header">
                      <h3>{messageContent.header}</h3>
                      <small>{messageContent.body}</small>
                    </div>
                  </div>
                )}
              <div className="form-group">
                <label
                  htmlFor="display_name"
                  className="form-control-label"
                  style={{ display: "block", color: "#ccc" }}
                >
                  Display Name
                </label>
                <input
                  id="display_name"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                  className="form-control"
                  maxLength={20}
                  style={{
                      marginTop: "0.5rem",
                      display: "block",
                      width: "100%",
                      border: "none", 
                      fontSize: 16
                  }}
                />
              </div>

              <div className="form-group m-t">
                <label
                  htmlFor="bio"
                  className="form-control-label"
                  style={{ display: "block", color: "#ccc" }}
                >
                  Bio
                </label>
                  <textarea
                    id="bio"
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    className="form-control"
                    placeholder=""
                    maxLength={200}
                    style={{
                      display: "block",
                      marginTop: "0.5rem",
                      width: "100%",
                      height: 80,
                      fontSize: 16
                    }}
                  />
              </div>

              <div className="pad30"></div>
              <button
                className="btn btn-fw primary"
                style={{ width: "100%" }}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? <>Saving...</> : <>Save</>}
              </button>
            </form>
          </AutoColumn>
        </ContentWrapper>
      </UpperSection>
    );
  }

  return (
    <Modal
      isOpen={walletModalOpen}
      onDismiss={toggleProfileModal}
      minHeight={false}
      maxHeight={90}
      dialogStyles={{ border: "none", borderRadius: 10 }}
    >
      <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
  );
}
