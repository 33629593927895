import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentSong: null,
  isPlaying: false,
  playlist: [],
  nextSongId: null,
  previousSongId: null,
};

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    addSong(state, { payload }) {
      const exists = state.playlist.find(item => item.song_id === payload.song_id);
      if (!exists) {
        state.playlist.push(payload);
        // Recalculate navigation references whenever the playlist changes
        updateNavigationReferences(state);
      }
    },
    addSongAndPlay(state, { payload }) {
      const exists = state.playlist.find(item => item.song_id === payload.song_id);
      if (!exists) {
        state.playlist.push(payload);
      }
      state.currentSong = payload;
      state.isPlaying = true;
      //console.log(state.currentSong)
      updateNavigationReferences(state);
    },
    removeSong(state, { payload }) {
      const indexToRemove = state.playlist.findIndex(song => song.song_id === payload.song_id);
      state.playlist.splice(indexToRemove, 1);
      updateNavigationReferences(state);
    },
    clearPlaylist(state) {
      state.playlist = [];
      state.currentSong = null;
      state.isPlaying = false;
      state.nextSongId = null;
      state.previousSongId = null;
    },
    play(state) {
      if (state.currentSong) {
        state.isPlaying = true;
      } else {
        if (state.playlist.length > 0) {
          state.currentSong = state.playlist[0];
          state.isPlaying = true;
        }
      }
      updateNavigationReferences(state);
    },
    stop(state) {
      state.isPlaying = false;
    },
    playNext(state) {
      //console.log("state.nextSongId", state.nextSongId)
      if (state.nextSongId) {
        state.currentSong = state.playlist.find(song => song.song_id === state.nextSongId);
        state.isPlaying = true;
        updateNavigationReferences(state);
      }
    },
    playPrevious(state) {
      //console.log("state.previousSongId", state.previousSongId)
      if (state.previousSongId) {
        state.currentSong = state.playlist.find(song => song.song_id === state.previousSongId);
        state.isPlaying = true;
        updateNavigationReferences(state);
      }
    },
    setCurrentSong(state, { payload }) {
      state.currentSong = state.playlist.find(song => song.song_id === payload.song_id) || null;
      updateNavigationReferences(state);
    }
  },
});

function updateNavigationReferences(state) {
  const currentIndex = state.playlist.findIndex(song => song.song_id === state.currentSong?.song_id);
  const nextIndex = (currentIndex + 1) % state.playlist.length;
  const prevIndex = currentIndex - 1 < 0 ? state.playlist.length - 1 : currentIndex - 1;
  state.nextSongId = state.playlist[nextIndex]?.song_id || null;
  state.previousSongId = state.playlist[prevIndex]?.song_id || null;
}

export const {
  addSong,
  removeSong,
  play,
  stop,
  clearPlaylist,
  playNext,
  playPrevious,
  setCurrentSong,
  addSongAndPlay
} = playerSlice.actions;

export default playerSlice.reducer;
