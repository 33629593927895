import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  Dashboard,
  Chart,
  Create,
  Listen, 
  Profile, 
  Maintenance, 
  Army
} from "./pages";

import { Buttons } from "./components";
import Popups from './components/Popups'
import Layout from './components/Layout'

import "./styles/index.scss";

function App() {
  return (
    <>
      <Popups />
      <BrowserRouter>
        <Layout className="app dk" id="app">
          <Routes>
            <Route path="/chart" element={<Chart />} />
            <Route path="/discover" element={<Dashboard />} />
            <Route path="/listen/:songId" element={<Listen />} />
            <Route path="/create" element={<Create />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/army" element={<Army />} />

            <Route path="/" element={<Dashboard />} />
          </Routes>
        </Layout>
        <Buttons.BackToTop />
      </BrowserRouter>
    </>
  );
}

export default App;
