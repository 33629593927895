import React from "react";

const ToggleSwitch = ({ id, checked, onChange, label, mini = false }) => {
    return (
        <span className={`${mini ? "mini" : ""}`}>
            <label className="switch">
                <input
                    type="checkbox"
                    className="form-check-input"
                    id={id}
                    checked={checked}
                    onChange={onChange}
                />
                <span className="slider"></span>
            </label>
            {label && <span className="switch-label">{label}</span>}
        </span>
    );
};

export default ToggleSwitch;
