import { createSlice, nanoid } from "@reduxjs/toolkit";
import { DEFAULT_TXN_DISMISS_MS } from "../../constants/misc";

import { SupportedChainId } from "../../constants/chains";

export const PopupContent = [
  {
    txn: {
      hash: "",
    },
  },
  {
    failedSwitchNetwork: SupportedChainId,
  },
];

export const ApplicationModal = {
  ADDRESS_CLAIM: "ADDRESS_CLAIM",
  BLOCKED_ACCOUNT: "BLOCKED_ACCOUNT",
  CLAIM_POPUP: "CLAIM_POPUP",
  DELEGATE: "DELEGATE",
  EXECUTE: "EXECUTE",
  FEATURE_FLAGS: "FEATURE_FLAGS",
  FIAT_ONRAMP: "FIAT_ONRAMP",
  MENU: "MENU",
  METAMASK_CONNECTION_ERROR: "METAMASK_CONNECTION_ERROR",
  NETWORK_FILTER: "NETWORK_FILTER",
  NETWORK_SELECTOR: "NETWORK_SELECTOR",
  POOL_OVERVIEW_OPTIONS: "POOL_OVERVIEW_OPTIONS",
  PRIVACY_POLICY: "PRIVACY_POLICY",
  QUEUE: "QUEUE",
  SELF_CLAIM: "SELF_CLAIM",
  SETTINGS: "SETTINGS",
  SHARE: "SHARE",
  TIME_SELECTOR: "TIME_SELECTOR",
  VOTE: "VOTE",
  WALLET: "WALLET",
  WALLET_DROPDOWN: "WALLET_DROPDOWN",
  UNISWAP_NFT_AIRDROP_CLAIM: "UNISWAP_NFT_AIRDROP_CLAIM",
  PROFILE: "PROFILE_EDIT",
  NOW_PLAYING: "NOW_PLAYING",
};

const initialState = {
  chainId: null,
  openModal: null,
  popupList: [],
  isSideMenuOpen: false,
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    updateChainId(state, action) {
      const { chainId } = action.payload;
      state.chainId = chainId;
    },
    setOpenModal(state, action) {
      state.openModal = action.payload;
    },
    addPopup(
      state,
      { payload: { content, key, removeAfterMs = DEFAULT_TXN_DISMISS_MS } }
    ) {
      state.popupList = (
        key
          ? state.popupList.filter((popup) => popup.key !== key)
          : state.popupList
      ).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ]);
    },
    removePopup(state, { payload: { key } }) {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false;
        }
      });
    },
    toggleSideMenu(state) {
      state.isSideMenuOpen = !state.isSideMenuOpen;
    },
  },
});

export const { updateChainId, setOpenModal, addPopup, removePopup, toggleSideMenu } =
  applicationSlice.actions;
export default applicationSlice.reducer;
